import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Login
  },
  {
    path: "/Login",
    name: "Login",
    component: Login
  },
  // 总览
  // {
  //   path: '/allView',
  //   name: 'allView',
  //   component: () => import('../views/allView.vue')
  // },
  // {
  //   path: '/main',
  //   component: () => import('../views/main'),
  //   children: [
  //     {
  //       path: '/',
  //       name: 'alone',
  //       component: () => import('../views/alone.vue')
  //     },
  //     {
  //       path: '/alone',
  //       name: 'alone',
  //       component: () => import('../views/alone.vue')
  //     },
  //      // 综合监测
  //     {
  //       path: '/whole',
  //       name: 'whole',
  //       component: () => import('../views/whole/wholeIndex.vue')
  //     },
  //   ]
  // },

  //   {
  //     path: "/home",
  //     name: 'home',
  //     redirect:'/allView'
  // },

  {
    path: "/main",
    name: "main",
    component: () => import("../views/main.vue"),
    children: [
      //  空导航下进入
      {
        path: "/empty",
        name: "empty",
        component: () => import("../views/empty.vue")
      },
      // 施工总览
      {
        path: "/constructionOverview",
        name: "construction",
        component: () => import("../views/construction/index.vue")
      },
      // 线形监控
      {
        path: "/linearMonitoring",
        name: "linear",
        component: () => import("../views/linearMonitoring/index.vue")
      },
      // 立模标高
      {
        path: "/formworkElevation",
        component: () => import("../views/formworkElevation/index.vue")
      },
      // 挂篮变形
      {
        path: "/basketDeformation",
        component: () => import("../views/basketDeformation/index.vue")
      },
      // 轴线偏位
      {
        path: "/axisDeviation",
        component: () => import("../views/axisDeviation/index.vue")
      },
      // 索塔偏位
      {
        path: "/cableTowerDeflection",
        component: () => import("../views/cableTowerDeflection/index.vue")
      },
      // 应力监控
      {
        path: "/stressMonitoring",
        name: "stress",
        component: () => import("../views/stressMonitoring/index.vue")
      },
      // 截面尺寸
      {
        path: "/sectionSize",
        name: "sectionSize",
        component: () => import("../views/sectionSize/index.vue")
      },
      // 索力监控
      {
        path: "/cableForceMonitoring",
        name: "cableforce",
        component: () => import("../views/cableForce/index.vue")
      },
      // 控制台 培森
      // 施工进度
      {
        path: "/controlSettings",
        component: () => import("../views/controlSettings/progress.vue")
      },
      // 施工配置
      {
        path: "/configuration",
        component: () => import("../views/controlSettings/configure.vue")
      },
      // 外观配置
      {
        path: "/appearanceConfiguration",
        component: () => import("../views/controlSettings/appearance.vue")
      },
      // 理论值设置
      {
        path: "/theoreticalValue",
        component: () => import("../views/controlSettings/theoreticalValue.vue")
      },
      // 巡检养护 新增页面
      {
        path: "/inspectionMaintenance",
        name: "maintenance",
        component: () => import("../views/maintenance/index.vue")
      },
      {
        path: "/",
        name: "home",
        component: () => import("../views/allView.vue")
      },
      {
        path: "/home",
        name: "allView",
        component: () => import("../views/allView.vue")
      },
      {
        path: "/model3D",
        name: "model3D",
        component: () => import("../../src/components/alone/model3D.vue")
      },
      // // ----------------综合监测------------------
      {
        path: "/whole",
        name: "whole",
        component: () => import("../views/whole/wholeIndex.vue")
      },
      // 测点定位
      {
        path: "/positionSet",
        name: "positionSet",
        component: () => import("../../src/views/position/index.vue")
      },

      // ----------------------------预警管理------------------------------------
      {
        path: "/warning",
        component: () => import("../views/warning/set/SetList.vue"),
        children: [
          {
            path: "/",
            component: () => import("../views/warning/set/components/Single.vue")
          },
          {
            path: "/overall",
            component: () => import("../views/warning/set/components/Overall.vue")
          },
          {
            path: "/degeneration",
            component: () => import("../views/warning/set/components/Degeneration.vue")
          },
          {
            path: "/structure",
            component: () => import("../views/warning/set/components/Structure.vue")
          },
          {
            path: "/unusual",
            component: () => import("../views/warning/set/components/Unusual.vue")
          },
          {
            path: "/dynamic",
            component: () => import("../views/warning/set/components/Dynamic.vue")
          }
        ]
      },
      {
        path: "/setDetail/:id",
        component: () => import("../views/warning/set/components/SetDetail.vue")
      },
      {
        path: "/SetDynamicDetail/:id",
        component: () => import("../views/warning/set/components/SetDynamicDetail.vue")
      },
      {
        path: "/setDegeneration/:id",
        component: () => import("../views/warning/set/components/SetDegeneration.vue")
      },
      {
        path: "/warningList",
        component: () => import("../views/warning/WarningList.vue")
      },

      // 数据查看
      {
        path: "/monitorData", // 环境类监测数据
        component: () => import("../views/dataView/monitorData.vue")
      },
      {
        path: "/measureLook", // 离线监测
        component: () => import("../views/dataView/measureLook.vue")
      },
      {
        path: "/dataAnalysisView",
        component: () => import("../views/dataView/dataAnalysisView/index.vue"),
        children: [
          {
            path: "/",
            component: () => import("../views/dataView/dataAnalysisView/timeDomainAnalysis.vue")
          },
          {
            path: "/timeDomainAnalysis",
            name: "timeDomainAnalysis",
            component: () => import("../views/dataView/dataAnalysisView/timeDomainAnalysis.vue")
          },
          {
            path: "/staticTimeDomainAnalysis",
            name: "staticTimeDomainAnalysis",
            component: () => import("../views/dataView/dataAnalysisView/staticTimeDomainAnalysis.vue")
          },
          {
            path: "/temAnalysis",
            name: "temAnalysis",
            component: () => import("../views/dataView/dataAnalysisView/temAnalysis.vue")
          },
          {
            path: "/frequencyAnalysis",
            name: "frequencyAnalysis",
            component: () => import("../views/dataView/dataAnalysisView/frequencyAnalysis.vue")
          },
          {
            path: "/trendAnalysis",
            name: "trendAnalysis",
            component: () => import("../views/dataView/dataAnalysisView/trendAnalysis.vue")
          },
          {
            path: "/relativeAnalysis", // 相关性分析
            name: "relativeAnalysis",
            component: () => import("../views/dataView/dataAnalysisView/relativeAnalysis.vue")
          },
          // simulation
          {
            path: "/simulation", // 在线仿真
            name: "simulation",
            component: () => import("../views/dataView/dataAnalysisView/loadIndex.vue"),
            children: [
              {
                path: "/deadLoad", // 重力荷载
                name: "deadIndex",
                component: () => import("../views/dataView/dataAnalysisView/load/dead.vue")
                // component: () => import('../views/dataView/dataAnalysisView/simulation.vue')
              },
              {
                path: "/deadLoadSansheng", // 重力荷载
                name: "deadIndexSansheng",
                component: () => import("../views/dataView/dataAnalysisView/load/dead_sansheng.vue")
                // component: () => import('../views/dataView/dataAnalysisView/simulation.vue')
              },
              {
                path: "/deadLoadBadusan", // 重力荷载
                name: "deadIndexBadusan",
                component: () => import("../views/dataView/dataAnalysisView/load/dead_badusan.vue")
                // component: () => import('../views/dataView/dataAnalysisView/simulation.vue')
              },
              {
                path: "/deadLoadDawang", // 重力荷载
                name: "deadLoadDawang",
                component: () => import("../views/dataView/dataAnalysisView/load/dead_dawang.vue")
                // component: () => import('../views/dataView/dataAnalysisView/simulation.vue')
              },
              {
                path: "/temperature", // 温度荷载
                name: "temperature",
                component: () => import("../views/dataView/dataAnalysisView/simulation.vue")
              },
              {
                path: "/seismic", // 地震荷载
                name: "seismic",
                component: () => import("../views/dataView/dataAnalysisView/load/seismic.vue")
              },
              {
                path: "/liveLoad", // 活载工况
                name: "liveLoad",
                component: () => import("../views/dataView/dataAnalysisView/load/liveLoad.vue")
              },
              {
                path: "/flowLoad", // 水流荷载
                name: "flowLoad",
                component: () => import("../views/dataView/dataAnalysisView/load/flowLoad.vue")
              }
            ]
          },
          {
            path: "/effectPrediction", // 效应预测 1.0
            name: "effectPrediction",
            component: () => import("../views/dataView/dataAnalysisView/effectPrediction.vue")
          },
          {
            path: "/effectPredictionSetting", // 效应预测 1.0 配合机荷demo
            name: "effectPredictionSetting",
            component: () => import("../views/dataView/dataAnalysisView/effectPredictionSetting.vue")
          },
          {
            path: "/effectPredictionTwo", // 效应预测 2.0
            name: "effectPredictionTwo",
            component: () => import("../views/dataView/dataAnalysisView/effectPredictionTwo.vue")
          },
          {
            path: "/dynamicAnalysis", // 动位移分析
            name: "dynamicAnalysis",
            component: () => import("../views/dataView/dataAnalysisView/dynamicAnalysis.vue")
          },
          {
            path: "/modalAnalysis", // 模态分析
            name: "modalAnalysis",
            component: () => import("../views/dataView/dataAnalysisView/modalAnalysis.vue")
          },
          {
            path: "/modelIndex", // 新模态------模态分析
            name: "modelIndex",
            component: () => import("../views/dataView/model/modelIndex.vue")
          },
          {
            path: "/damageIdentify", // 损伤识别
            name: "damageIdentify",
            component: () => import("../views/dataView/dataAnalysisView/damageIdentify.vue")
          }
        ]
      },

      // --------------------------------基础信息----------------------------
      {
        path: "/survey",
        name: "survey",
        component: () => import("../views/information/survey.vue")
      },
      {
        path: "/distinguish",
        name: "distinguish",
        component: () => import("../views/information/distinguish.vue")
      },
      {
        path: "/means",
        name: "means",
        component: () => import("../views/information/means.vue")
      },
      {
        path: "/position",
        name: "position",
        component: () => import("../views/information/position.vue")
      },
      {
        path: "/curing",
        component: () => import("../views/information/curing/index.vue"),
        children: [
          {
            path: "/",
            component: () => import("../views/information/curing/daily.vue")
          },
          {
            path: "/daily/:id",
            name: "daily",
            component: () => import("../views/information/curing/daily.vue")
          }
        ]
      },

      //   operationMaintenance
      //   运维总览
      {
        path: "/operationMaintenance",
        name: "operationMaintenance",
        component: () => import("../views/operationMaintenance/operationMaintenance.vue"),
        meta: { routeNone: true }
      },

      // ---------------------偏差统计---------------------------------

      {
        path: "/deviation",
        name: "deviation",
        component: () => import("../views/deviation/deviation.vue")
      },
      // ---------------------应力张拉---------------------------------

      {
        path: "/tressTensioning",
        name: "tressTensioning",
        component: () => import("../views/tressTensioning/tressTensioning.vue")
      },

      // ---------------------资料管理---------------------------------

      {
        path: "/dataMamagement",
        name: "dataMamagement",
        component: () => import("../views/dataMamagement/index.vue"),
        children: [
          {
            path: "/basicFile", // 基础资料
            component: () => import("../views/dataMamagement/BasicFile.vue")
          },
          {
            path: "/inspectionFile", // 巡检资料
            component: () => import("../views/dataMamagement/InspectionFile.vue")
          },
          {
            path: "/constructionFile", // 施工资料
            component: () => import("../views/dataMamagement/ConstructionFile.vue")
          },
          {
            path: "/mold", //  立模指令
            component: () => import("../views/dataMamagement/mold.vue")
          },
          {
            path: "/measurementRecord", // 测量记录
            name: "measurementRecord",
            component: () => import("../views/dataMamagement/measurementRecord.vue")
          },
          {
            path: "/engineerDraw", // 工程图纸
            name: "engineerDraw",
            component: () => import("../views/dataMamagement/engineerDraw.vue")
          },
          {
            path: "/constructionOrg", // 施工组织
            name: "constructionOrg",
            component: () => import("../views/dataMamagement/constructionOrg.vue")
          },
          {
            path: "/custom", // 自定义项
            name: "custom",
            component: () => import("../views/dataMamagement/custom.vue")
          }
        ]
      },

      // ---------------------数据管理---------------------------------
      {
        path: "/handleList",
        name: "handleList",
        component: () => import("../views/dataHandling/handleList.vue")
      },
      {
        path: "/analysis",
        name: "analysis",
        component: () => import("../views/dataHandling/analysis.vue")
      },
      {
        path: "/analysisDetail",
        name: "analysisDetail",
        component: () => import("../views/dataHandling/analysisDetail.vue")
      },
      {
        path: "/detailsSetting",
        name: "detailsSetting",
        component: () => import("../views/dataHandling/detailsSetting.vue")
      },
      {
        path: "/secondary",
        name: "secondary",
        component: () => import("../views/dataHandling/secondary.vue")
      },
      {
        path: "/secondaryDetail",
        name: "secondaryDetail",
        component: () => import("../views/dataHandling/secondaryDetail.vue")
      },
      {
        path: "/data_overall", //全局参数设置
        name: "data_overall",
        component: () => import("../views/dataHandling/data_overall.vue")
      },

      // ----------------------设备管理------------------------------------------------
      // 数据处理
      {
        path: "/settings",
        component: () => import("../views/devManage/settings.vue")
      },
      // 数据处理
      {
        path: "/settingData/:id",
        component: () => import("../views/devManage/settingData.vue")
      },
      // 查看数据处理
      // {
      //   path: '/ViewSetting/id',
      //   component: () => import('../views/devManage/ViewSetting.vue')
      // },
      {
        path: "/devManage",
        component: () => import("../views/devManage/index.vue") //DataList
      },
      // 添加视频
      {
        path: "/setVideo",
        component: () => import("../views/devManage/setDevInfo/setVideo.vue") //DataList
      },
      //  查看视频
      {
        path: "/ViewVideo/:id",
        component: () => import("../views/devManage/setDevInfo/ViewVideo.vue") //DataList
      },
      // 测量值输入
      {
        path: "/measure",
        component: () => import("../views/devManage/setDevInfo/measure.vue")
      },
      {
        path: "/handlePoint",
        component: () => import("../views/devManage/setDevInfo/HandlePoint.vue")
      },
      {
        path: "/viewPoint/:id",
        component: () => import("../views/devManage/setDevInfo/ViewPoint.vue")
      },
      {
        path: "/transmissionControl",
        component: () => import("../views/devManage/TransmissionControl.vue")
      },
      {
        path: "/setParams",
        component: () => import("../views/devManage/SetParams.vue")
      },

      // 权限管理
      {
        path: "/company",
        name: "company",
        component: () => import("../views/system/company.vue")
      },
      {
        path: "/addRole/:id",
        component: () => import("../views/system/roleManage/addRole.vue")
      },
      {
        path: "/roleList",
        name: "roleList",
        component: () => import("../views/system/roleManage/roleList.vue")
      },
      {
        path: "/addAdmin",
        name: "addAdmin",
        component: () => import("../views/system/adminManage/addAdmin.vue")
      },
      {
        path: "/adminList",
        name: "adminList",
        component: () => import("../views/system/adminManage/adminList.vue")
      },
      {
        path: "/addPro",
        name: "addPro",
        component: () => import("../views/system/proManage/addPro.vue")
      },
      {
        path: "/proList",
        name: "proList",
        component: () => import("../views/system/proManage/proList.vue")
      },
      // 统计报表  report
      {
        path: "/report",
        name: "report",
        component: () => import("../views/report/report.vue")
      },
      //-------------------------- 健康评估----------------------
      {
        path: "/healthy",
        name: "healthy",
        component: () => import("../views/healthy/index.vue"),
        children: [
          {
            path: "/healthIndex",
            name: "healthIndex",
            component: () => import("../views/healthy/common/healthIndex.vue")
          },
          {
            path: "/regularInspection",
            name: "regularInspection",
            component: () => import("../views/healthy/common/regularInspection.vue")
          }
        ]
      },

      // healthySet 健康评估--设置
      {
        path: "/healthySet",
        name: "healthySet",
        component: () => import("../views/healthy/healthySet.vue")
      },

      // -------------------------基础信息   建设信息 升级改造，日月报--------------------------
      {
        path: "/documentList",
        name: "documentList",
        component: () => import("../views/documentManage/documentList.vue")
      },
      {
        path: "/pdfPage",
        name: 'pdfPage',
        component: () => import("../views/report/pdfPage.vue"),
      },
      // 车辆荷载监测
      {
        path: "/vehicleLoad",
        component: () => import("../views/vehicleLoad/index.vue")
      },
      // 健康度评估
      {
        path: "/healthDegree",
        component: () => import("../views/healthDegree/index.vue")
      },
    ]
  },

  // 重要路由

  // 报表
  {
    path: "/report",
    component: () => import("../views/report/report.vue"),
    children: [
      {
        path: "/",
        name: "report",
        component: () => import("../views/report/report.vue")
      },
      {
        path: "/report",
        name: "report",
        component: () => import("../views/report/report.vue")
      }
    ]
  }



  // {
  //   path: '/Home',
  //   component: () => import('../views/Home.vue'),
  //   children:[
  //     {
  //       path: '/',
  //       name: 'map',
  //       component: () => import('../views/map.vue')
  //     },
  //     {
  //       path: '/system',
  //       name: 'system',
  //       component: () => import('../views/system/index.vue'),
  //       children:[
  //            {
  //              path: '/',
  //              name: 'company',
  //              component: () => import('../views/system/company.vue')
  //            },
  //           {
  //             path: '/company',
  //             name: 'company',
  //             component: () => import('../views/system/company.vue')
  //           },
  //            {
  //             path: '/addRole/:id',
  //             component: () => import('../views/system/roleManage/addRole.vue')
  //           },{
  //             path: '/roleList',
  //             name: 'roleList',
  //             component: () => import('../views/system/roleManage/roleList.vue')
  //           },{
  //             path: '/addAdmin',
  //             name: 'addAdmin',
  //             component: () => import('../views/system/adminManage/addAdmin.vue')
  //           },{
  //             path: '/adminList',
  //             name: 'adminList',
  //             component: () => import('../views/system/adminManage/adminList.vue')
  //           },{
  //             path: '/addPro',
  //             name: 'addPro',
  //             component: () => import('../views/system/proManage/addPro.vue')
  //           },{
  //             path: '/proList',
  //             name: 'proList',
  //             component: () => import('../views/system/proManage/proList.vue')
  //           }
  //      ]
  //     },
  //     {
  //       path: '/index',
  //       // name: 'index',
  //       component: () => import('../views/index.vue'),
  //       redirect:'/single',
  //       children:[
  //         {
  //           path: '/noUse',
  //           name: 'noUse',
  //           component: () => import('../components/noUse.vue')
  //         },
  //         {
  //           path: '/single',
  //           name: 'single',
  //           component: () => import('../views/single.vue')
  //         },
  //         {
  //           path: '/survey',
  //           name: 'survey',
  //           component: () => import('../views/information/survey.vue')
  //         },
  //         {
  //             path: '/distinguish',
  //             name: 'distinguish',
  //             component: () => import('../views/information/distinguish.vue')
  //         },
  //         {
  //           path: '/means',
  //           name: 'means',
  //           component: () => import('../views/information/means.vue')
  //         },
  //         {
  //           path: '/position',
  //           name: 'position',
  //           component: () => import('../views/information/position.vue')
  //         },
  //         {
  //           path: '/curing',
  //           component: () => import('../views/information/curing/index.vue'),
  //           children:[
  //             {
  //               path: '/',
  //               component: () => import('../views/information/curing/daily.vue'),
  //             },
  //             {
  //               path: '/daily/:id',
  //               name: 'daily',
  //               component: () => import('../views/information/curing/daily.vue')
  //             },
  //           ]
  //         },
  //         {
  //           path: '/handleList',
  //           name: 'handleList',
  //           component: () => import('../views/dataHandling/handleList.vue')
  //         },
  //         {
  //           path: '/analysis',
  //           name: 'analysis',
  //           component: () => import('../views/dataHandling/analysis.vue')
  //         },
  //         {
  //           path: '/analysisDetail',
  //           name: 'analysisDetail',
  //           component: () => import('../views/dataHandling/analysisDetail.vue')
  //         },
  //         {
  //           path: '/detailsSetting',
  //           name: 'detailsSetting',
  //           component: () => import('../views/dataHandling/detailsSetting.vue')
  //         },
  //         {
  //           path: '/secondary',
  //           name: 'secondary',
  //           component: () => import('../views/dataHandling/secondary.vue')
  //         },
  //         {
  //           path: '/secondaryDetail',
  //           name: 'secondaryDetail',
  //           component: () => import('../views/dataHandling/secondaryDetail.vue')
  //         },
  //         {
  //           path: '/data_overall',   //全局参数设置
  //           name: 'data_overall',
  //           component: () => import('../views/dataHandling/data_overall.vue')
  //         },
  //         {
  //           path: '/warning',
  //           component: () => import('../views/warning/set/SetList.vue'),
  //           children: [
  //             {
  //               path: '/',
  //               component: () => import('../views/warning/set/components/Single.vue')
  //             },{
  //               path: '/overall',
  //               component: () => import('../views/warning/set/components/Overall.vue')
  //             },{
  //               path: '/degeneration',
  //               component: () => import('../views/warning/set/components/Degeneration.vue')
  //             },{
  //               path: '/structure',
  //               component: () => import('../views/warning/set/components/Structure.vue')
  //             },{
  //               path: '/unusual',
  //               component: () => import('../views/warning/set/components/Unusual.vue')
  //             }
  //           ]
  //         },
  //         {
  //           path: '/setDetail/:id',
  //           component: () => import('../views/warning/set/components/SetDetail.vue')
  //         },
  //         {
  //           path: '/setDegeneration/:id',
  //           component: () => import('../views/warning/set/components/SetDegeneration.vue')
  //         },
  //         {
  //           path: '/warningList',
  //           component: () => import('../views/warning/WarningList.vue')
  //         },
  //         // 设备信息
  //         {
  //           path: '/devManage',
  //           component: () => import('../views/devManage/setDevInfo/DataList.vue')
  //         },
  //         // 测量值输入
  //         {
  //           path: '/measure',
  //           component: () => import('../views/devManage/setDevInfo/measure.vue')
  //         },
  //         {
  //           path: '/handlePoint',
  //           component: () => import('../views/devManage/setDevInfo/HandlePoint.vue')
  //         },
  //         {
  //           path: '/viewPoint/:id',
  //           component: () => import('../views/devManage/setDevInfo/ViewPoint.vue')
  //         },
  //         {
  //           path: '/transmissionControl',
  //           component: () => import('../views/devManage/TransmissionControl.vue')
  //         },
  //         {
  //           path: '/setParams',
  //           component: () => import('../views/devManage/SetParams.vue')
  //         },
  //         {
  //           path: '/monitorData', // 环境类监测数据
  //           component: () => import('../views/dataView/monitorData.vue')
  //         },
  //         {
  //           path: '/measureLook', // 离线监测
  //           component: () => import('../views/dataView/measureLook.vue')
  //         },

  //         {
  //           path: '/documentList',
  //           name: 'documentList',
  //           component: () => import('../views/documentManage/documentList.vue')
  //         },
  //         {
  //           path: '/dataAnalysisView',
  //           component: () => import('../views/dataView/dataAnalysisView/index.vue'),
  //           children:[
  //             {
  //               path: '/',
  //               component: () => import('../views/dataView/dataAnalysisView/timeDomainAnalysis.vue')
  //             },{
  //               path: '/timeDomainAnalysis',
  //               name: 'timeDomainAnalysis',
  //               component: () => import('../views/dataView/dataAnalysisView/timeDomainAnalysis.vue')
  //             },{
  //               path: '/staticTimeDomainAnalysis',
  //               name: 'staticTimeDomainAnalysis',
  //               component: () => import('../views/dataView/dataAnalysisView/staticTimeDomainAnalysis.vue')
  //             },{
  //               path: '/temAnalysis',
  //               name: 'temAnalysis',
  //               component: () => import('../views/dataView/dataAnalysisView/temAnalysis.vue')
  //             },{
  //               path: '/frequencyAnalysis',
  //               name: 'frequencyAnalysis',
  //               component: () => import('../views/dataView/dataAnalysisView/frequencyAnalysis.vue')
  //             },{
  //               path: '/trendAnalysis',
  //               name: 'trendAnalysis',
  //               component: () => import('../views/dataView/dataAnalysisView/trendAnalysis.vue')
  //             },{
  //               path: '/relativeAnalysis', // 相关性分析
  //               name: 'relativeAnalysis',
  //               component: () => import('../views/dataView/dataAnalysisView/relativeAnalysis.vue')
  //             },{
  //               path: '/effectPrediction', // 效应预测
  //               name: 'effectPrediction',
  //               component: () => import('../views/dataView/dataAnalysisView/effectPrediction.vue')
  //             },{
  //               path: '/dynamicAnalysis', // 动位移分析
  //               name: 'dynamicAnalysis',
  //               component: () => import('../views/dataView/dataAnalysisView/dynamicAnalysis.vue')
  //             },{
  //               path: '/modalAnalysis', // 模态分析
  //               name: 'modalAnalysis',
  //               component: () => import('../views/dataView/dataAnalysisView/modalAnalysis.vue')
  //             },{
  //               path: '/damageIdentify', // 损伤识别
  //               name: 'damageIdentify',
  //               component: () => import('../views/dataView/dataAnalysisView/damageIdentify.vue')
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // }
];

//以下代码解决路由地址重复点击的报错问题
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  // mode: 'hash',              //二级目录记得注释
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path == "/Login") {
    next();
  } else {
    let token = sessionStorage.getItem("token");
    if (token) {
      if (to.path === '/deadLoad') {
        var projectId = sessionStorage.getItem("projectId");
        if (projectId == "10034") {
          next('/deadLoadBadusan');
        }
        if (projectId == "10032") {
          next('/deadLoadSansheng');
        }
        if (projectId == "10046") {
          next('/deadLoadDawang');
        }
      } else {
        next();
      }
    } else {
      next({ path: "/Login" });
    }
  }
});

export default router;
