<template>
  <div id="Login" :style="{ backgroundImage: `url(${bgImgSrc})`, backgroundSize: 'cover' }">
    <div class="company" v-show="appearanceConfig.copyrightStatus == 1">{{ appearanceConfig.copyright }}</div>
    <div class="title box center">
      <div class="iconImg mR5">
        <img :src="comLogoSrc" alt="" />
      </div>
      <!-- <div class="fs32 text">云奚数字孪生平台</div> -->
      <div class="fs32 text">{{ appearanceConfig.smallTitle }}</div>
    </div>
    <div class="line">
      <div class="text1">欢迎！</div>
      <div class="text2">{{ appearanceConfig.bigTitle }}</div>
      <!-- <div class="text2">云奚数字孪生平台</div> -->
    </div>
    <!-- <sign></sign> -->
    <div class="main box box_column">
      <div class="titleTop"></div>
      <div class="mainBox flex1" v-show="loginReg == 1">
        <div class="box justify fs16 tab">
          <div class="pointer" :class="active == 1 ? 'activeColor' : ''" @click="tab('1')">账号密码登录</div>
          <div class="shu" v-show="appearanceConfig.webId == 1"></div>
          <div v-show="appearanceConfig.webId == 1" class="pointer box center" :class="active == 2 ? 'activeColor' : ''" @click="tab('2')">
            <div class="m2 mR5"></div>
            <div>桥梁巡检h5</div>
          </div>
        </div>
        <sign v-if="active == 1"></sign>
        <div v-else style="color: #000">
          <div class="box around">
            <img :src="codeSrc" alt="" />
          </div>
          <div class="box around yidong" style="">移动应用</div>
        </div>
      </div>
      <div class="mainBox flex1" v-show="loginReg == 2" style="margin-top: 32px">
        <keep-alive>
          <register></register>
        </keep-alive>
      </div>
      <div class="textL reg pointer" v-if="loginReg == 1 && active == 1" @click="regTab('1')">没有账号，立即注册</div>
      <div class="textL reg pointer" v-if="loginReg == 2 && active == 1" @click="regTab('2')">已有账号，快速登录</div>
    </div>
  </div>
</template>

<script>
import register from "../components/login/register.vue"; //登录
import sign from "../components/login/sign.vue"; //登录

export default {
  name: "Login",
  components: {
    register, //登录
    sign
  },
  data() {
    return {
      bgImgSrc: "", // 背景图片
      codeSrc: "", // 二维码图片
      appearanceConfig: "", // 外观配置数据
      comLogoSrc: "", // 企业logo
      active: "1", //登录和二维码
      defaultName: 'admin_test', // 测试环境默认登录 admin_test
      loginReg: "1" //登录和注册
    };
  },
  beforeDestroy() {
    //页面关闭时清除定时器
  },
  created() {
    this.$bus.$on("successful", this.successful);
  },
  mounted() {
    this.getAppearanceData();
  },
  methods: {
    // 获取外观配置数据
    getAppearanceData() {
      let name = ''
      let address = window.location.href
      // let address = 'http://peisenbridge.ylsas.com/#/Login' // 正式环境地址
      // let address = 'http://webtest1.ylsas.com/bridgetest/#/Login' // 测试环境地址
      let str = address.split('.ylsas')[0].split('http://')[1]
      if (str.indexOf('bridge') != -1) {
        name = str.split('bridge')[0]
      } else {
        name = this.defaultName
      }
      this.$axios.get(`${this.baseURL}base/aspect/get/config?name=${name}`).then((res) => {
        if (res.status == "200") {
          console.log("外观配置----------------", res);
          sessionStorage.setItem("systemName", res.data.data.systemName)
          sessionStorage.setItem("urlIcon", res.data.data.firmLogoId)
          document.title = res.data.data.systemName
          document.getElementById('myIcon').href=`${this.baseURL}sys/sysImages/img/${res.data.data.webId}`

          // let odiv=document.createElement("link");
          // odiv.rel="shortcut icon";
          // odiv.href='http://114.116.93.53:8197/sys/sysImages/img/943'
          // document.getElementsByTagName('head')[0].appendChild(odiv)

          this.appearanceConfig = res.data.data;
          this.comLogoSrc = `${this.baseURL}sys/sysImages/img/${res.data.data.firmLogoId}`;
          this.bgImgSrc = `${this.baseURL}sys/sysImages/img/${res.data.data.loginId}`;
          this.codeSrc = `${this.baseURL}sys/sysImages/img/${res.data.data.codeId}`;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    successful(e) {
      this.loginReg = 1;
    },

    // 切换tab控件
    tab(val) {
      this.active = val;
    },
    regTab(val) {
      if (val == 1) {
        this.loginReg = 2;
      } else {
        this.loginReg = 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#Login {
  width: 100%;
  height: 100%;
  /* background: url("../assets/images/loginBg.png"); */
  /* background-size: 100% 100%; */
  position: relative;
}

.title {
  position: absolute;
  top: 60px;
  left: 79px;
}

.main {
  width: 395px;
  height: 459px;
  position: absolute;
  top: 22%;
  right: 18%;
  border-radius: 20px;
  background: #ffffff !important;
  padding-bottom: 60px;
}

.titleTop {
  background: url("../assets/images/img/titleBg.png");
  background-size: 100% 100%;
  border-radius: 20px;
  width: 395px;
  height: 70px;
}

.mainBox {
  margin-left: 81px;
  margin-right: 76px;
}

.tab {
  margin-top: 49px;
  color: #b0b2b6;
  margin-bottom: 36px;
}

.pointer {
  letter-spacing: 1px !important;
}

.shu {
  width: 1px;
  height: 20px;
  background-color: #e7edff;
}

.activeColor {
  color: #1f61ff !important;
  font-weight: 500 !important;
}

.m2 {
  width: 16px;
  height: 16px;
  background: url("../assets/images/img/2m.png");
  background-size: 100% 100%;
}

.iconImg {
  height: 42px;
  /* width: 70px; */
  /* height: 68px; */
  /* background: url("../assets/images/img/logo.png"); */
  background-size: 100% 100%;
  img {
    height: 42px;
    /* width: 70px; */
  }
}

.text {
  //  letter-spacing: 4px;
  font-weight: bold;
  letter-spacing: 1px;
  //  font-family: 'pingfang_thin';
}

.line {
  position: absolute;
  top: 340px;
  left: 130px;

  .text1 {
    color: #fff;
    font-size: 62px;
    font-weight: 800;
  }

  .text2 {
    color: #fff;
    font-size: 62px;
    // font-family: "pingfang_thin";
    font-weight: 100;
    width: 868px;
    font-family: PingFangSC-Thin, PingFang SC;
    // font-family: SimHei;   //sans-serif  NSimSun(仿宋) SimSun（宋） SimHei（黑）
  }
}

.reg {
  text-align: right;
  font-size: 12px;
  color: #1f61ff;
  letter-spacing: 2px !important;
  padding-right: 76px;
}

.yidong {
  font-size: 16px;
  margin-top: 26px;
  letter-spacing: 6px;
  font-weight: 700;
  color: #5a5a5a;
}

.company {
  position: absolute;
  bottom: 27px;
  left: 50%;
  margin-left: -184px;
}
</style>
