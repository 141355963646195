/**
 * 各种画echarts图表的方法都封装在这里
 */

// import echarts from 'echarts'
import * as echarts from "echarts";
// import tuli01 from '../assets/img/tuli01.png';
// import tuli02 from '../assets/img/tuli02.png';

const install = function (Vue) {
  Object.defineProperties(Vue.prototype, {
    $chart1: {
      get() {
        return {
          //历史数据  趋势预测
          line2: function (id, time, y1, y2, unit, showed) {
            this.chart = echarts.init(document.getElementById(id));
            // this.chart.clear();
            const optionData = {
              grid: {
                top: "8%",
                left: "2%",
                right: "0.5%",
                bottom: "6%",
                containLabel: true
              },
              // tooltip : {       //鼠标移入一条竖线   悬浮提示框样式
              //     trigger: "axis",
              //     borderWidth:'1',
              //     backgroundColor: "rgba(13,22,29,0.89)", //设置背景图片 rgba格式
              //     borderColor:'rgba(255, 255, 255, 0.49)',
              //     // borderColor:'rgba(199,199,199,0.9)',
              //     textStyle:{
              //         align:'left'
              //     },
              //     // formatter: '<span style="color:#c4c9ca">采集时间：{b}<span><br/><span style="color:#c4c9ca">当 前 值：{c0}'+unit+'<span><br/>',
              //     formatter: function (params) {
              //         console.log('params', params);

              //         // do some thing
              //         var res = params[0].name + '<br/>' + params[0].seriesName + params[0].value  + '<br/>' + params[1].seriesName + params[1].value
              //         return res;
              //      }
              // },

              tooltip: {
                trigger: "axis",
                borderWidth: "1"
              },

              dataZoom: [
                {
                  type: "slider",
                  show: true,
                  startValue: 0,
                  endValue: 5,
                  height: "40",
                  bottom: "0%",
                  // zoomLock: true,
                  start: 0, //数据窗口范围的起始百分比,表示30%
                  end: 100,
                  dataBackground: {
                    //数据阴影的样式。
                    // lineStyle:'#fffff',              //阴影的线条样式
                    // areaStyle:'#122757',              //阴影的填充样式
                  },
                  // fillerColor:"rgba(223, 254, 87, 0.19)",  //选中范围的填充颜色
                  // backgroundColor: 'rgba(37, 255, 149, 0.1)',
                  fillerColor: "rgba(39, 114, 240, 0.05)", //选中范围的填充颜色
                  textStyle: {
                    color: "#E4F0F2"
                  },
                  handleColor: "rgba(255, 254, 255, 0.50)", //滑块两端的线
                  borderColor: "rgba(0,0,0,0)" //边框
                  // handleIcon: require("../images/alone/zuobianjie_tuodong_kuang.png")
                }
              ],

              legend: {
                orient: "horizontal",
                itemGap: 20,
                x: "right", //可设定图例在左、右、居中
                y: "top", //可设定图例在上、下、居中   center
                textStyle: { color: "#fff" }, //文字颜色
                padding: [10, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                data: ["当前值", "预测值"]
              },

              xAxis: {
                type: "category", //
                // type: 'time',
                data: time,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#FBFBFB",
                    width: 1 //这里是为了突出显示加上的
                  }
                },
                axisTick: {
                  //y轴刻度线
                  show: false
                },
                // axisLabel: {   //日期倾斜
                //     // interval:100,    //显示间隔
                //     rotate:-20,
                //     textStyle: {
                //         // color: '#000',
                //         fontSize:'10',
                //         // itemSize:''

                //     }
                //  }

                axisLabel: {
                  //日期倾斜
                  // interval:100,    //显示间隔
                  rotate: -20,
                  textStyle: {
                    // color: '#000',
                    fontSize: "10"
                    // itemSize:''
                  },
                  formatter: function (value) {
                    return value.split(" ").join("\n");
                  }
                }
              },
              yAxis: [
                {
                  scale: true, //y轴不已0为基础显示
                  type: "value",
                  // name : '单位: 次',
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      // 使用深浅的间隔色
                      color: ["#9D9D9D"] //横线
                    }
                  },
                  nameTextStyle: {
                    color: ["#FBFBFB"] //单位的颜色
                  },
                  axisLine: {
                    show: false, //true false
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  },
                  axisTick: {
                    //y轴刻度线
                    show: false
                  }
                }
                // {
                //     axisLine:{
                //         show:true,
                //         lineStyle:{
                //             color:'#FBFBFB',
                //             width:1,//这里是为了突出显示加上的
                //         }
                //     }
                // },
              ],
              series: [
                {
                  name: "当前值",
                  data: y1,
                  type: "line",
                  showSymbol: false,
                  smooth: true, //false true
                  symbol: "circle", //曲线节点是实色
                  color: "#2772F0", //legend对应的颜色
                  // itemStyle: {

                  //     // normal: {
                  //     //     lineStyle:{
                  //     //         width: 3,
                  //     //         type:'solid',        //'dotted'虚线 'solid'实线
                  //     //         color: '#2772F0'     //线条颜色
                  //     //     }

                  //     // }
                  // },
                  itemStyle: {
                    normal: {
                      areaStyle: {
                        type: "default",
                        //渐变色实现===
                        color: new echarts.graphic.LinearGradient(
                          0,
                          0,
                          0,
                          1,
                          //三种由深及浅的颜色
                          [
                            {
                              offset: 0,
                              color: "#142653"
                            },
                            {
                              offset: 1,
                              color: "#17336f"
                            }
                          ]
                        )
                      },
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#2772F0" //线条颜色
                      },
                      //以及在折线图每个日期点顶端显示数字
                      label: {
                        show: true,
                        position: "top",
                        textStyle: {
                          color: "white"
                        }
                      }
                    }
                  },
                  areaStyle: {}
                },
                {
                  name: "预测值",
                  data: y2,
                  type: "line",
                  showSymbol: false,
                  smooth: true, //false true
                  symbol: "circle",
                  color: "#e0ad19",
                  itemStyle: {
                    normal: {
                      areaStyle: {
                        type: "default",
                        //渐变色实现===
                        color: new echarts.graphic.LinearGradient(
                          0,
                          0,
                          0,
                          1,
                          //三种由深及浅的颜色
                          [
                            {
                              offset: 0,
                              color: "#142653"
                            },
                            {
                              offset: 1,
                              color: "#394459"
                            }
                          ]
                        )
                      },
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#e0ad19" //线条颜色
                      },
                      //以及在折线图每个日期点顶端显示数字
                      label: {
                        show: true,
                        position: "top",
                        textStyle: {
                          color: "white"
                        }
                      }
                    }
                  },
                  areaStyle: {}
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },

          //历史数据
          line1: function (id, time, data, unit) {
            this.chart = echarts.init(document.getElementById(id));
            // this.chart.clear();
            const optionData = {
              grid: {
                top: "8%",
                left: "2%",
                right: "0.5%",
                bottom: "6%",
                containLabel: true
              },
              tooltip: {
                //鼠标移入一条竖线   悬浮提示框样式
                trigger: "axis",
                borderWidth: "1",
                backgroundColor: "rgba(13,22,29,0.89)", //设置背景图片 rgba格式
                borderColor: "rgba(255, 255, 255, 0.49)",
                // borderColor:'rgba(199,199,199,0.9)',
                textStyle: {
                  align: "left"
                },
                formatter: '<span style="color:#c4c9ca">采集时间：{b}<span><br/><span style="color:#c4c9ca">当 前 值：{c0}' + unit + "<span><br/>"
              },

              xAxis: {
                type: "category", //
                // type: 'time',
                data: time,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#FBFBFB",
                    width: 1 //这里是为了突出显示加上的
                  }
                },
                axisTick: {
                  //y轴刻度线
                  show: false
                },
                axisLabel: {
                  //日期倾斜
                  // interval:100,    //显示间隔
                  rotate: -20,
                  textStyle: {
                    // color: '#000',
                    fontSize: "10"
                    // itemSize:''
                  }
                }
              },
              yAxis: [
                {
                  scale: true, //y轴不已0为基础显示
                  type: "value",
                  // name : '单位: 次',
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      // 使用深浅的间隔色
                      color: ["#9D9D9D"] //横线
                    }
                  },
                  nameTextStyle: {
                    color: ["#FBFBFB"] //单位的颜色
                  },
                  axisLine: {
                    show: true,
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  },
                  axisTick: {
                    //y轴刻度线
                    show: false
                  }
                },
                {
                  axisLine: {
                    show: true,
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  }
                }
              ],
              series: [
                {
                  data: data,
                  type: "line",
                  showSymbol: false,
                  smooth: false,
                  itemStyle: {
                    normal: {
                      lineStyle: {
                        width: 1,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#86E8FF" //线条颜色
                      }
                    }
                  }
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },

          line11: function (id, time, data, data1, unit, showed) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();

            const optionData = {
              grid: {
                top: "10%",
                left: "2%",
                right: "0.5%",
                bottom: "18%",
                containLabel: true
              },
              dataZoom: [
                {
                  type: "slider",
                  show: true,
                  startValue: 0,
                  endValue: 5,
                  height: "40",
                  bottom: "0%",
                  // zoomLock: true,
                  // start: 80,                                //数据窗口范围的起始百分比,表示30%
                  // end: 100,
                  dataBackground: {
                    //数据阴影的样式。
                    // lineStyle:'#fffff',              //阴影的线条样式
                    // areaStyle:'#122757',              //阴影的填充样式
                  },
                  // fillerColor:"rgba(223, 254, 87, 0.19)",  //选中范围的填充颜色
                  // backgroundColor: 'rgba(37, 255, 149, 0.1)',
                  fillerColor: "rgba(39, 114, 240, 0.05)", //选中范围的填充颜色
                  textStyle: {
                    color: "#E4F0F2"
                  },
                  handleColor: "rgba(255, 254, 255, 0.50)", //滑块两端的线
                  borderColor: "rgba(0,0,0,0)" //边框
                  // handleIcon: require("../images/alone/zuobianjie_tuodong_kuang.png")
                }
              ],

              title: {
                show: showed, // 是否显示title
                text: "暂无数据",
                top: "20",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 16,
                  fontWeight: 200
                }
              },

              tooltip: {
                trigger: "axis",
                borderWidth: "1",
                borderColor: "#fff",
                textStyle: {
                  align: "left"
                },
                formatter: "{b}" + "<br/>" + "{a0}: {c0} " + "<br/>" + "{a1}: {c1} "
              },

              legend: {
                data: "频率",
                icon: "roundRect", //rect ，roundRect
                // bottom:0,
                x: "right", //left
                y: "top", //top
                padding: [0, 14, 0, 0],
                // top:'center',
                itemWidth: 20,
                itemHeight: 2,
                textStyle: {
                  color: "#ffffff"
                }
                //  selected: {
                //     '最小值': false,
                //   }
              },

              xAxis: {
                type: "category", //
                data: time,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#FBFBFB",
                    width: 1 //这里是为了突出显示加上的
                  }
                },
                axisTick: {
                  //y轴刻度线
                  show: false
                },
                axisLabel: {
                  //日期倾斜
                  // interval:100,    //显示间隔
                  // rotate:-20,     //旋转-20度
                  textStyle: {
                    // color: '#000',
                    fontSize: "10"
                    // itemSize:''
                  },
                  formatter: function (value) {
                    return value.split(" ").join("\n");
                  }
                }
              },

              yAxis: [
                {
                  scale: true, //y轴不已0为基础显示
                  type: "value",
                  // name : '单位: 次',
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      // 使用深浅的间隔色
                      color: ["#9D9D9D"] //横线
                    }
                  },
                  nameTextStyle: {
                    color: ["#FBFBFB"] //单位的颜色
                  },
                  axisLine: {
                    show: false, //true false
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  },
                  axisTick: {
                    //y轴刻度线
                    show: false
                  }
                },
                {
                  // name: text1 + '(' + unit1 + ')',
                  nameTextStyle: {
                    padding: [0, 0, 0, -40] // 四个数字分别为上右下左与原位置距离
                  },
                  scale: true,
                  splitLine: {
                    show: false,
                    lineStyle: {
                      color: ["#a8b3ba"],
                      width: 1,
                      type: "solid"
                    }
                  },
                  axisLine: {
                    //y轴颜色
                    lineStyle: {
                      // color: "#fff",
                      color: "rgba(220,220,220,0)",
                      width: 1
                    }
                  },
                  axisLabel: {
                    //y轴数字颜色
                    show: true,
                    textStyle: {
                      // color: "#fff"
                      color: "rgba(220,220,220,0)"
                    },
                    formatter: function (value, index) {
                      return value.toFixed(1);
                    }
                  }
                }
              ],
              series: [
                {
                  data: data,
                  name: "频率",
                  type: "line",
                  showSymbol: false, //是否显示原点
                  smooth: true, //开启光滑
                  // symbol: 'circle', //设定为实心点
                  // symbolSize: 10, //设定实心点的大小
                  itemStyle: {
                    normal: {
                      color: "#2772F0", // 小圆点颜色
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#2772F0" //线条颜色
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      //右，下，左，上
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "#122757"
                          },
                          {
                            offset: 1,
                            // color:'rgba(255,255,255, 0)'
                            color: "#122757"
                          }
                        ],
                        false
                      )
                    }
                  }
                },
                {
                  name: "阻尼比",
                  type: "line",
                  yAxisIndex: "1",
                  symbol: "circle",
                  //显示文字label和小圆点颜色
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "rgba(220,220,220,0)"
                    }
                  },
                  // showAllSymbol:true
                  showSymbol: true,
                  data: data1
                  // lineStyle: {
                  //   color: "#09b0f5",
                  // },
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },

          // 原始值
          line: function (id, time, data, name, unit, showed, wData) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();

            const optionData = {
              grid: {
                top: "14%",
                left: "4%",
                right: "3%",
                bottom: "12%",
                containLabel: true
              },
              dataZoom: [
                {
                  type: "slider",
                  show: true,
                  startValue: 0,
                  endValue: 5,
                  height: "40",
                  bottom: "0%",
                  // zoomLock: true,
                  start: 80, //数据窗口范围的起始百分比,表示30%
                  end: 100,
                  dataBackground: {
                    //数据阴影的样式。
                    // lineStyle:'#fffff',              //阴影的线条样式
                    // areaStyle:'#122757',              //阴影的填充样式
                  },
                  // fillerColor:"rgba(223, 254, 87, 0.19)",  //选中范围的填充颜色
                  // backgroundColor: 'rgba(37, 255, 149, 0.1)',
                  fillerColor: "rgba(39, 114, 240, 0.05)", //选中范围的填充颜色
                  textStyle: {
                    color: "#E4F0F2"
                  },
                  handleColor: "rgba(255, 254, 255, 0.50)", //滑块两端的线
                  borderColor: "rgba(0,0,0,0)" //边框
                  // handleIcon: require("../images/alone/zuobianjie_tuodong_kuang.png")
                }
              ],

              title: {
                show: showed, // 是否显示title
                text: "暂无数据",
                top: "20",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 16,
                  fontWeight: 200
                }
              },

              tooltip: {
                trigger: "axis",
                //  axisPointer: {
                //      type: 'cross',
                //     label: {
                //         backgroundColor: '#6a7985'
                //     }
                // },
                // trigger: "axis",
                borderWidth: "1"
                // borderColor:'#fff',
                // textStyle:{
                //     align:'left'
                // },
                // formatter: '{b}'+'<br/>'+'{a0}: {c0} '+'<br/>'+'{a1}: {c1} ',
                // formatter: '{b}'+'<br/>',
              },
              legend: {
                data: name,
                icon: "roundRect", //rect ，roundRect
                // bottom:0,
                x: "right", //left
                y: "top", //top
                padding: [3, 14, 0, 0],
                // top:'center',
                itemWidth: 20,
                itemHeight: 2,
                textStyle: {
                  color: "#ffffff"
                }
                //  selected: {
                //     '最小值': false,
                //   }
              },

              xAxis: {
                type: "category", //
                data: time,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#FBFBFB",
                    width: 1 //这里是为了突出显示加上的
                  }
                },
                axisTick: {
                  //y轴刻度线
                  show: false
                },
                axisLabel: {
                  //日期倾斜
                  // interval:100,    //显示间隔
                  rotate: -20,
                  textStyle: {
                    // color: '#000',
                    fontSize: "10"
                    // itemSize:''
                  },
                  formatter: function (value) {
                    return value.split(" ").join("\n");
                  }
                }
              },

              yAxis: [
                {
                  scale: true, //y轴不已0为基础显示
                  type: "value",
                  // name : '单位: 次',
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      // 使用深浅的间隔色
                      color: ["#9D9D9D"] //横线
                    }
                  },
                  nameTextStyle: {
                    color: ["#FBFBFB"] //单位的颜色
                  },
                  axisLine: {
                    show: false, //true false
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  },
                  axisTick: {
                    //y轴刻度线
                    show: false
                  }
                }
              ],
              series: [
                {
                  markLine: {
                    symbol: "none", //去掉警戒线最后面的箭头
                    label: {
                      formatter: "一级警告",
                      position: "end" //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                    },
                    data: wData
                  },
                  data: data,
                  name: "原始值",
                  type: "line",
                  showSymbol: false, //是否显示原点
                  smooth: true, //开启光滑
                  // symbol: 'circle', //设定为实心点
                  // symbolSize: 10, //设定实心点的大小
                  itemStyle: {
                    normal: {
                      color: "#2772F0", // 小圆点颜色
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#2772F0" //线条颜色
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      //右，下，左，上
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "#122757"
                          },
                          {
                            offset: 1,
                            // color:'rgba(255,255,255, 0)'
                            color: "#122757"
                          }
                        ],
                        false
                      )
                    }
                  }
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },

          line_live: function (id, time, data, name, unit, showed) {
            this.chart = echarts.init(document.getElementById(id));
            // this.chart.clear();

            const optionData = {
              grid: {
                top: "10%",
                left: "2%",
                right: "0.5%",
                bottom: "18%",
                containLabel: true
              },
              // dataZoom: [
              //   {
              //     type: "slider",
              //     show: true,
              //     startValue: 0,
              //     endValue: 5,
              //     height: "40",
              //     bottom: "0%",
              //     // zoomLock: true,
              //     start: 80, //数据窗口范围的起始百分比,表示30%
              //     end: 100,
              //     dataBackground: {
              //       //数据阴影的样式。
              //       // lineStyle:'#fffff',              //阴影的线条样式
              //       // areaStyle:'#122757',              //阴影的填充样式
              //     },
              //     // fillerColor:"rgba(223, 254, 87, 0.19)",  //选中范围的填充颜色
              //     // backgroundColor: 'rgba(37, 255, 149, 0.1)',
              //     fillerColor: "rgba(39, 114, 240, 0.05)", //选中范围的填充颜色
              //     textStyle: {
              //       color: "#E4F0F2"
              //     },
              //     handleColor: "rgba(255, 254, 255, 0.50)", //滑块两端的线
              //     borderColor: "rgba(0,0,0,0)" //边框
              //     // handleIcon: require("../images/alone/zuobianjie_tuodong_kuang.png")
              //   }
              // ],

              title: {
                show: showed, // 是否显示title
                text: "暂无数据",
                top: "20",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 16,
                  fontWeight: 200
                }
              },

              tooltip: {
                trigger: "axis",
                //  axisPointer: {
                //      type: 'cross',
                //     label: {
                //         backgroundColor: '#6a7985'
                //     }
                // },
                // trigger: "axis",
                borderWidth: "1"
                // borderColor:'#fff',
                // textStyle:{
                //     align:'left'
                // },
                // formatter: '{b}'+'<br/>'+'{a0}: {c0} '+'<br/>'+'{a1}: {c1} ',
                // formatter: '{b}'+'<br/>',
              },
              legend: {
                data: name,
                icon: "roundRect", //rect ，roundRect
                // bottom:0,
                x: "right", //left
                y: "top", //top
                padding: [3, 14, 0, 0],
                // top:'center',
                itemWidth: 20,
                itemHeight: 2,
                textStyle: {
                  color: "#ffffff"
                }
                //  selected: {
                //     '最小值': false,
                //   }
              },

              xAxis: {
                type: "category", //
                data: time,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#FBFBFB",
                    width: 1 //这里是为了突出显示加上的
                  }
                },
                axisTick: {
                  //y轴刻度线
                  show: false
                },
                axisLabel: {
                  //日期倾斜
                  // interval:100,    //显示间隔
                  rotate: -20,
                  textStyle: {
                    // color: '#000',
                    fontSize: "10"
                    // itemSize:''
                  },
                  formatter: function (value) {
                    return value.split(" ").join("\n");
                  }
                }
              },

              yAxis: [
                {
                  scale: true, //y轴不已0为基础显示
                  type: "value",
                  // name : '单位: 次',
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      // 使用深浅的间隔色
                      color: ["#9D9D9D"] //横线
                    }
                  },
                  nameTextStyle: {
                    color: ["#FBFBFB"] //单位的颜色
                  },
                  axisLine: {
                    show: false, //true false
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  },
                  axisTick: {
                    //y轴刻度线
                    show: false
                  }
                }
              ],
              series: [
                {
                  data: data,
                  name: "原始值",
                  type: "line",
                  showSymbol: false, //是否显示原点
                  smooth: true, //开启光滑
                  // symbol: 'circle', //设定为实心点
                  // symbolSize: 10, //设定实心点的大小
                  itemStyle: {
                    normal: {
                      color: "#2772F0", // 小圆点颜色
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#2772F0" //线条颜色
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      //右，下，左，上
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "#122757"
                          },
                          {
                            offset: 1,
                            // color:'rgba(255,255,255, 0)'
                            color: "#122757"
                          }
                        ],
                        false
                      )
                    }
                  }
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },

          //自定义条加入
          // max  min  average  standard variance   range
          // [最大值,最小值,平均值,标准差,方差,极差]
          moreLineDemo: function (id, time, max, min, average, standard, variance, range, name, unit, showed) {
            // var myRegression = ecStat.regression('linear', average);

            // myRegression.points.sort(function (a, b) {
            //     return a[0] - b[0];
            // });

            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            const optionData = {
              grid: {
                top: "20%",
                left: "2%",
                right: "0.5%",
                bottom: "14%",
                containLabel: true
              },
              title: {
                show: showed, // 是否显示title
                text: "暂无数据",
                top: "50",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 16,
                  fontWeight: 200
                }
              },
              // tooltip : {       //鼠标移入一条竖线   悬浮提示框样式
              //     trigger: "axis",
              //     borderWidth:'1',
              //     backgroundColor: "rgba(13,22,29,0.89)", //设置背景图片 rgba格式
              //     borderColor:'rgba(255, 255, 255, 0.49)',
              //     // borderColor:'rgba(199,199,199,0.9)',
              //     textStyle:{
              //         align:'left'
              //     },
              //     formatter: '<span style="color:#c4c9ca">采集时间：{b}<span><br/><span style="color:#c4c9ca">当 前 值：{c0}'+unit+'<span><br/>',
              // },
              dataZoom: [
                {
                  type: "slider",
                  show: true,
                  startValue: 0,
                  endValue: 5,
                  height: "40",
                  bottom: "0%",
                  // zoomLock: true,
                  start: 80, //数据窗口范围的起始百分比,表示30%
                  end: 100,
                  dataBackground: {
                    //数据阴影的样式。
                    // lineStyle:'#fffff',              //阴影的线条样式
                    // areaStyle:'#122757',              //阴影的填充样式
                  },
                  // fillerColor:"rgba(223, 254, 87, 0.19)",  //选中范围的填充颜色
                  // backgroundColor: 'rgba(37, 255, 149, 0.1)',
                  fillerColor: "rgba(39, 114, 240, 0.05)", //选中范围的填充颜色
                  textStyle: {
                    color: "#E4F0F2"
                  },
                  handleColor: "rgba(255, 254, 255, 0.50)", //滑块两端的线
                  borderColor: "rgba(0,0,0,0)" //边框
                  // handleIcon: require("../images/alone/zuobianjie_tuodong_kuang.png")
                }
              ],

              legend: {
                data: name,
                icon: "roundRect", //rect ，roundRect
                // bottom:0,
                x: "right", //left
                y: "top", //top
                padding: [10, 14, 0, 0],
                // top:'center',
                itemWidth: 20,
                itemHeight: 2,
                textStyle: {
                  color: "#ffffff"
                }
                //  selected: {
                //     '最小值': false,
                //   }
              },

              tooltip: {
                trigger: "axis",
                //  axisPointer: {
                //      type: 'cross',
                //     label: {
                //         backgroundColor: '#6a7985'
                //     }
                // },
                // trigger: "axis",
                borderWidth: "1"
                // borderColor:'#fff',
                // textStyle:{
                //     align:'left'
                // },
                // formatter: '{b}'+'<br/>'+'{a0}: {c0} '+'<br/>'+'{a1}: {c1} ',
                // formatter: '{b}'+'<br/>',
              },

              xAxis: {
                type: "category", //
                data: time,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#FBFBFB",
                    width: 1 //这里是为了突出显示加上的
                  }
                },
                axisTick: {
                  //y轴刻度线
                  show: false
                },
                axisLabel: {
                  //日期倾斜
                  // interval:100,    //显示间隔
                  rotate: -20,
                  textStyle: {
                    // color: '#000',
                    fontSize: "10"
                    // itemSize:''
                  },
                  formatter: function (value) {
                    return value.split(" ").join("\n");
                  }
                }
              },

              yAxis: [
                {
                  scale: true, //y轴不已0为基础显示
                  type: "value",
                  // name : '单位: 次',
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      // 使用深浅的间隔色
                      color: ["#9D9D9D"] //横线
                    }
                  },
                  nameTextStyle: {
                    color: ["#FBFBFB"] //单位的颜色
                  },
                  axisLine: {
                    show: false, //true false
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  },
                  axisTick: {
                    //y轴刻度线
                    show: false
                  }
                },
                {
                  // name: text1 + '(' + unit1 + ')',
                  nameTextStyle: {
                    padding: [0, 0, 0, -40] // 四个数字分别为上右下左与原位置距离
                  },
                  scale: true,
                  splitLine: {
                    show: false,
                    lineStyle: {
                      color: ["#a8b3ba"],
                      width: 1,
                      type: "solid"
                    }
                  },
                  axisLine: {
                    //y轴颜色
                    lineStyle: {
                      // color: "#fff",
                      color: "rgba(220,220,220,0)",
                      width: 1
                    }
                  },
                  axisLabel: {
                    //y轴数字颜色
                    show: true,
                    textStyle: {
                      // color: "#fff"
                      color: "rgba(220,220,220,0)"
                    },
                    formatter: function (value, index) {
                      return value.toFixed(1);
                    }
                  }
                }
                // {
                //     axisLine:{
                //         show:true,
                //         lineStyle:{
                //             color:'#FBFBFB',
                //             width:1,//这里是为了突出显示加上的
                //         }
                //     }
                // },
              ],
              series: [
                // 最大值
                {
                  data: max,
                  name: "最大值",
                  type: "line",
                  showSymbol: false, //是否显示原点
                  smooth: true, //开启光滑
                  // symbol: 'circle', //设定为实心点
                  // symbolSize: 10, //设定实心点的大小
                  itemStyle: {
                    normal: {
                      color: "#2772F0", // 小圆点颜色
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#2772F0" //线条颜色
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      //右，下，左，上
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "#122757"
                          },
                          {
                            offset: 1,
                            // color:'rgba(255,255,255, 0)'
                            color: "#122757"
                          }
                        ],
                        false
                      )
                    }
                  }
                },
                // 均值
                {
                  data: average,
                  name: "均值",
                  type: "line",
                  showSymbol: false,
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "#F7B500", //小圆点颜色
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#F7B500" //线条颜色
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      //右，下，左，上
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "#353f4e"
                          },
                          {
                            offset: 1,
                            color: "#353f4e"
                          }
                        ],
                        false
                      )
                    }
                  }
                },
                // 最小值
                {
                  data: min,
                  name: "最小值",
                  type: "line",
                  showSymbol: false,
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "#80C0FF", //小圆点线条颜色
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#80C0FF" //线条颜色
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      //右，下，左，上
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "#2a3b59"
                          },
                          {
                            offset: 1,
                            color: "#2a3b59"
                          }
                        ],
                        false
                      )
                    }
                  }
                },

                // 标准值
                {
                  name: "标准值",
                  type: "line",
                  data: standard,
                  yAxisIndex: "1",
                  symbol: "circle",
                  //显示文字label和小圆点颜色
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "rgba(220,220,220,0)"
                    }
                  },
                  showSymbol: true
                },
                // 方差
                {
                  name: "方差",
                  type: "line",
                  data: variance,
                  yAxisIndex: "1",
                  symbol: "circle",
                  //显示文字label和小圆点颜色
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "rgba(220,220,220,0)"
                    }
                  },
                  showSymbol: true
                },
                // 极差
                {
                  name: "极差",
                  type: "line",
                  data: range,
                  yAxisIndex: "1",
                  symbol: "circle",
                  //显示文字label和小圆点颜色
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "rgba(220,220,220,0)"
                    }
                  },
                  showSymbol: true
                }
                // {
                //     name: 'line',
                //     type: 'line',
                //     showSymbol: false,
                //     data: myRegression.points,
                //     markPoint: {
                //         itemStyle: {
                //             normal: {
                //                 color: 'transparent'
                //             }
                //         },
                //         label: {
                //             normal: {
                //                 show: true,
                //                 formatter: myRegression.expression,
                //                 textStyle: {
                //                     color: '#333',
                //                     fontSize: 14
                //                 }
                //             }
                //         },
                //         data: [{
                //             coord: myRegression.points[myRegression.points.length - 1]
                //         }]
                //     }
                // }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          // data = []
          moreLine: function (id, time, max, min, average, standard, variance, range, name, unit, showed, data = []) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();

            // this.chart.resize()
            // let data= [
            //   //   {type: 'average', name: '平均值'}, // 设置平均线 可以有多个yAxis,多条’示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值

            // ]
            // if (markLineData.length) {
            //   for (let i = 0; i < markLineData.length; i++){
            //     let obj= {
            //       yAxis: 100,
            //       symbol:"none", // 去掉警戒线最后面的箭头
            //       silent:false, // 鼠标悬停事件  true没有，false有
            //       name: '警戒线', // 警戒线
            //       lineStyle: { // 警戒线样式
            //           type: 'solid',
            //           color: 'red'
            //       },
            //       label: {
            //            position: 'end',//将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
            //            formatter: '警戒线', // 名称
            //            fontSize: 14
            //           },
            //     }
            //     obj.yAxis = markLineData[i];
            //     data.push(obj)
            //   }
            // }

            // this.chart.clear();
            const optionData = {
              grid: {
                top: "20%",
                left: "5%",
                right: "3%",
                bottom: "28%",
                containLabel: false
              },
              title: {
                show: showed, // 是否显示title
                text: "暂无数据",
                top: "50",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 16,
                  fontWeight: 200
                }
              },
              // tooltip : {       //鼠标移入一条竖线   悬浮提示框样式
              //     trigger: "axis",
              //     borderWidth:'1',
              //     backgroundColor: "rgba(13,22,29,0.89)", //设置背景图片 rgba格式
              //     borderColor:'rgba(255, 255, 255, 0.49)',
              //     // borderColor:'rgba(199,199,199,0.9)',
              //     textStyle:{
              //         align:'left'
              //     },
              //     formatter: '<span style="color:#c4c9ca">采集时间：{b}<span><br/><span style="color:#c4c9ca">当 前 值：{c0}'+unit+'<span><br/>',
              // },
              dataZoom: [
                {
                  type: "slider",
                  show: true,
                  startValue: 0,
                  endValue: 5,
                  height: "40",
                  bottom: "0%",
                  // zoomLock: true,
                  start: 0, //数据窗口范围的起始百分比,表示30%
                  end: 100,
                  bottom:-10,
                  dataBackground: {
                    //数据阴影的样式。
                    // lineStyle:'#fffff',              //阴影的线条样式
                    // areaStyle:'#122757',              //阴影的填充样式
                  },
                  // fillerColor:"rgba(223, 254, 87, 0.19)",  //选中范围的填充颜色
                  // backgroundColor: 'rgba(37, 255, 149, 0.1)',
                  fillerColor: "rgba(39, 114, 240, 0.05)", //选中范围的填充颜色
                  textStyle: {
                    color: "#E4F0F2"
                  },
                  handleColor: "rgba(255, 254, 255, 0.50)", //滑块两端的线
                  borderColor: "rgba(0,0,0,0)" //边框
                  // handleIcon: require("../images/alone/zuobianjie_tuodong_kuang.png")
                }
              ],

              legend: {
                data: name,
                icon: "roundRect", //rect ，roundRect
                // bottom:0,
                x: "right", //left
                y: "top", //top
                padding: [10, 40, 0, 0],
                // top:'center',
                itemWidth: 20,
                itemHeight: 2,
                textStyle: {
                  color: "#ffffff",
                  fontSize: 12
                }
                //  selected: {
                //     '最小值': false,
                //   }
              },

              tooltip: {
                confine: true,
                trigger: "axis",
                //  axisPointer: {
                //      type: 'cross',
                //     label: {
                //         backgroundColor: '#6a7985'
                //     }
                // },
                // trigger: "axis",
                borderWidth: "1"
                // borderColor:'#fff',
                // textStyle:{
                //     align:'left'
                // },
                // formatter: '{b}'+'<br/>'+'{a0}: {c0} '+'<br/>'+'{a1}: {c1} ',
                // formatter: '{b}'+'<br/>',
              },

              xAxis: {
                type: "category", //
                data: time,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#FBFBFB",
                    width: 1 //这里是为了突出显示加上的
                  }
                },
                axisTick: {
                  //y轴刻度线
                  show: false
                },
                axisLabel: {
                  //日期倾斜
                  // interval:100,    //显示间隔
                  rotate: -20,
                  margin: 20, 
                  textStyle: {
                    // color: '#000',
                    fontSize: "10",
                    // itemSize:''
                  },
                  formatter: function (value) {
                    return value.split(" ").join("\n");
                  }
                }
              },

              yAxis: [
                {
                  scale: true, //y轴不已0为基础显示
                  type: "value",
                  // name : '单位: 次',
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      // 使用深浅的间隔色
                      color: ["#9D9D9D"] //横线
                    }
                  },
                  nameTextStyle: {
                    color: ["#FBFBFB"] //单位的颜色
                  },
                  axisLine: {
                    show: false, //true false
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  },
                  axisTick: {
                    //y轴刻度线
                    show: false
                  }
                },
                {
                  // name: text1 + '(' + unit1 + ')',
                  nameTextStyle: {
                    padding: [0, 0, 0, -40] // 四个数字分别为上右下左与原位置距离
                  },
                  scale: true,
                  splitLine: {
                    show: false,
                    lineStyle: {
                      color: ["#a8b3ba"],
                      width: 1,
                      type: "solid"
                    }
                  },
                  axisLine: {
                    //y轴颜色
                    lineStyle: {
                      // color: "#fff",
                      color: "rgba(220,220,220,0)",
                      width: 1
                    }
                  },
                  axisLabel: {
                    //y轴数字颜色
                    show: true,
                    textStyle: {
                      // color: "#fff"
                      color: "rgba(220,220,220,0)"
                    },
                    formatter: function (value, index) {
                      return value.toFixed(1);
                    }
                  }
                }
                // {
                //     axisLine:{
                //         show:true,
                //         lineStyle:{
                //             color:'#FBFBFB',
                //             width:1,//这里是为了突出显示加上的
                //         }
                //     }
                // },
              ],
              series: [
                // 最大值
                {
                  markLine: {
                    symbol: "none", //去掉警戒线最后面的箭头
                    label: {
                      formatter: "一级警告",
                      position: "end", //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                      distance: -20 
                    },
                    data: data
                  },
                  data: max,
                  name: "最大值",
                  type: "line",
                  showSymbol: false, //是否显示原点
                  smooth: true, //开启光滑
                  // symbol: 'circle', //设定为实心点
                  // symbolSize: 10, //设定实心点的大小
                  itemStyle: {
                    normal: {
                      color: "#2772F0", // 小圆点颜色
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#2772F0" //线条颜色
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      color: "rgba(39, 114, 240, 0.3)"  // 直接设置透明度为0.3的深蓝色
                    }
                  }
                },
                // 均值
                {
                  data: average,
                  name: "均值",
                  type: "line",
                  showSymbol: false,
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "#F7B500", //小圆点颜色
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#F7B500" //线条颜色
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      color: "rgba(247, 181, 0, 0.3)"  // 直接设置透明度为0.3的深蓝色
                    }
                  }
                },
                // 最小值
                {
                  data: min,
                  name: "最小值",
                  type: "line",
                  showSymbol: false,
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "#80C0FF", //小圆点线条颜色
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#80C0FF" //线条颜色
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      color: "rgba(42, 59, 89, 0.3)"  // 直接设置透明度为0.3的深蓝色
                    }
                  }
                },

                // 标准值
                {
                  name: "标准值",
                  type: "line",
                  data: standard,
                  yAxisIndex: "1",
                  symbol: "circle",
                  //显示文字label和小圆点颜色
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "rgba(220,220,220,0)"
                    }
                  },
                  showSymbol: true
                },
                // 方差
                {
                  name: "方差",
                  type: "line",
                  data: variance,
                  yAxisIndex: "1",
                  symbol: "circle",
                  //显示文字label和小圆点颜色
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "rgba(220,220,220,0)"
                    }
                  },
                  showSymbol: true
                },
                // 极差
                {
                  name: "极差",
                  type: "line",
                  data: range,
                  yAxisIndex: "1",
                  symbol: "circle",
                  //显示文字label和小圆点颜色
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "rgba(220,220,220,0)"
                    }
                  },
                  showSymbol: true
                }
              ]
            };

            this.chart.setOption(optionData);
            this.chart.resize();
            window.addEventListener("resize", () => {
              this.chart.resize();
            });

            // return this.chart;
          },
          moreLines: function (id, time, data1, data2, name, unit, showed, data = []) {
            this.chart = echarts.init(document.getElementById(id));

            // let data= [
            //   //   {type: 'average', name: '平均值'}, // 设置平均线 可以有多个yAxis,多条’示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值

            // ]
            // if (markLineData.length) {
            //   for (let i = 0; i < markLineData.length; i++){
            //     let obj= {
            //       yAxis: 100,
            //       symbol:"none", // 去掉警戒线最后面的箭头
            //       silent:false, // 鼠标悬停事件  true没有，false有
            //       name: '警戒线', // 警戒线
            //       lineStyle: { // 警戒线样式
            //           type: 'solid',
            //           color: 'red'
            //       },
            //       label: {
            //            position: 'end',//将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
            //            formatter: '警戒线', // 名称
            //            fontSize: 14
            //           },
            //     }
            //     obj.yAxis = markLineData[i];
            //     data.push(obj)
            //   }
            // }

            // this.chart.clear();
            const optionData = {
              grid: {
                top: "20%",
                left: "2%",
                right: "3%",
                bottom: "14%",
                containLabel: true
              },
              title: {
                show: showed, // 是否显示title
                text: "暂无数据",
                top: "50",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 16,
                  fontWeight: 200
                }
              },
              // tooltip : {       //鼠标移入一条竖线   悬浮提示框样式
              //     trigger: "axis",
              //     borderWidth:'1',
              //     backgroundColor: "rgba(13,22,29,0.89)", //设置背景图片 rgba格式
              //     borderColor:'rgba(255, 255, 255, 0.49)',
              //     // borderColor:'rgba(199,199,199,0.9)',
              //     textStyle:{
              //         align:'left'
              //     },
              //     formatter: '<span style="color:#c4c9ca">采集时间：{b}<span><br/><span style="color:#c4c9ca">当 前 值：{c0}'+unit+'<span><br/>',
              // },
              dataZoom: [
                {
                  type: "slider",
                  show: true,
                  startValue: 0,
                  endValue: 5,
                  height: "40",
                  bottom: "0%",
                  // zoomLock: true,
                  start: 0, //数据窗口范围的起始百分比,表示30%
                  end: 100,
                  dataBackground: {
                    //数据阴影的样式。
                    // lineStyle:'#fffff',              //阴影的线条样式
                    // areaStyle:'#122757',              //阴影的填充样式
                  },
                  // fillerColor:"rgba(223, 254, 87, 0.19)",  //选中范围的填充颜色
                  // backgroundColor: 'rgba(37, 255, 149, 0.1)',
                  fillerColor: "rgba(39, 114, 240, 0.05)", //选中范围的填充颜色
                  textStyle: {
                    color: "#E4F0F2"
                  },
                  handleColor: "rgba(255, 254, 255, 0.50)", //滑块两端的线
                  borderColor: "rgba(0,0,0,0)" //边框
                  // handleIcon: require("../images/alone/zuobianjie_tuodong_kuang.png")
                }
              ],

              legend: {
                data: name,
                icon: "roundRect", //rect ，roundRect
                // bottom:0,
                x: "right", //left
                y: "top", //top
                padding: [10, 14, 0, 0],
                // top:'center',
                itemWidth: 20,
                itemHeight: 2,
                textStyle: {
                  color: "#ffffff"
                  // color: "#58637f"
                }
                //  selected: {
                //     '最小值': false,
                //   }
              },

              tooltip: {
                trigger: "axis",
                //  axisPointer: {
                //      type: 'cross',
                //     label: {
                //         backgroundColor: '#6a7985'
                //     }
                // },
                // trigger: "axis",
                borderWidth: "1"
                // borderColor:'#fff',
                // textStyle:{
                //     align:'left'
                // },
                // formatter: '{b}'+'<br/>'+'{a0}: {c0} '+'<br/>'+'{a1}: {c1} ',
                // formatter: '{b}'+'<br/>',
              },

              xAxis: {
                type: "category", //
                data: time,
                axisLine: {
                  show: true,
                  lineStyle: {
                    // color: "#FBFBFB",
                    // width: 1 //这里是为了突出显示加上的
                    color: "#1c2b51",
                    type: "dashed"
                  }
                },
                axisTick: {
                  //y轴刻度线
                  show: false
                },
                axisLabel: {
                  color: "#8A94A6",

                  //日期倾斜
                  // interval:100,    //显示间隔
                  rotate: -20,
                  textStyle: {
                    color: "#fff",
                    fontSize: "10"
                    // itemSize:''
                  },
                  formatter: function (value) {
                    return value.split(" ").join("\n");
                  }
                }
              },

              yAxis: [
                {
                  scale: true, //y轴不已0为基础显示
                  type: "value",
                  // name : '单位: 次',
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      // 使用深浅的间隔色
                      // color: ["#9D9D9D"] //横线
                      color: "#1c2b51"
                    }
                  },
                  nameTextStyle: {
                    // color: ["#FBFBFB"] //单位的颜色
                    color: "#1c2b51"
                  },
                  axisLine: {
                    show: false, //true false
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  },
                  axisLabel: {
                    //  y轴左侧的文字
                    color: "#58637f",
                    fontSize: 14
                  },
                  axisTick: {
                    //y轴刻度线
                    show: false
                  }
                },
                {
                  // name: text1 + '(' + unit1 + ')',
                  nameTextStyle: {
                    padding: [0, 0, 0, -40] // 四个数字分别为上右下左与原位置距离
                  },
                  scale: true,
                  splitLine: {
                    show: false,
                    lineStyle: {
                      color: ["#a8b3ba"],
                      width: 1,
                      type: "solid"
                    }
                  },
                  axisLine: {
                    //y轴颜色
                    lineStyle: {
                      // color: "#fff",
                      color: "rgba(220,220,220,0)",
                      width: 1
                    }
                  },
                  axisLabel: {
                    //y轴数字颜色
                    show: true,
                    color: "#1c2b51",
                    textStyle: {
                      // color: "#fff"
                      // color: "rgba(220,220,220,0)"
                      color: "#1c2b51"
                    },
                    formatter: function (value, index) {
                      return value.toFixed(1);
                    }
                  }
                }
                // {
                //     axisLine:{
                //         show:true,
                //         lineStyle:{
                //             color:'#FBFBFB',
                //             width:1,//这里是为了突出显示加上的
                //         }
                //     }
                // },
              ],
              series: [
                // 应力
                {
                  markLine: {
                    symbol: "none", //去掉警戒线最后面的箭头
                    label: {
                      formatter: "一级警告",
                      position: "end" //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                    },
                    data
                  },
                  data: data1,
                  name: name[0],
                  type: "line",
                  showSymbol: false, //是否显示原点
                  smooth: true, //开启光滑
                  // symbol: 'circle', //设定为实心点
                  // symbolSize: 10, //设定实心点的大小
                  itemStyle: {
                    normal: {
                      color: "#2772F0", // 小圆点颜色
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#2772F0" //线条颜色
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      //右，下，左，上
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "#122757"
                          },
                          {
                            offset: 1,
                            // color:'rgba(255,255,255, 0)'
                            color: "#122757"
                          }
                        ],
                        false
                      )
                    }
                  }
                },
                // 应力变化量
                {
                  data: data2,
                  name: name[1],
                  type: "line",
                  showSymbol: false,
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "#F7B500", //小圆点颜色
                      lineStyle: {
                        width: 3,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#F7B500" //线条颜色
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      //右，下，左，上
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "#353f4e"
                          },
                          {
                            offset: 1,
                            color: "#353f4e"
                          }
                        ],
                        false
                      )
                    }
                  }
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          moreLinesPerson: function (id, time, data1, data2, name, unit, showed, data = []) {
            this.chart = echarts.init(document.getElementById(id));

            console.log("person-data", data);
            // let data= [
            //   //   {type: 'average', name: '平均值'}, // 设置平均线 可以有多个yAxis,多条’示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值

            // ]
            // if (markLineData.length) {
            //   for (let i = 0; i < markLineData.length; i++){
            //     let obj= {
            //       yAxis: 100,
            //       symbol:"none", // 去掉警戒线最后面的箭头
            //       silent:false, // 鼠标悬停事件  true没有，false有
            //       name: '警戒线', // 警戒线
            //       lineStyle: { // 警戒线样式
            //           type: 'solid',
            //           color: 'red'
            //       },
            //       label: {
            //            position: 'end',//将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
            //            formatter: '警戒线', // 名称
            //            fontSize: 14
            //           },
            //     }
            //     obj.yAxis = markLineData[i];
            //     data.push(obj)
            //   }
            // }

            // this.chart.clear();
            const optionData = {
              grid: {
                top: "20%",
                left: "2%",
                right: "3%",
                bottom: "10%",
                containLabel: true
              },
              title: {
                show: showed, // 是否显示title
                text: "暂无数据",
                top: "50",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 16,
                  fontWeight: 200
                }
              },
              // tooltip : {       //鼠标移入一条竖线   悬浮提示框样式
              //     trigger: "axis",
              //     borderWidth:'1',
              //     backgroundColor: "rgba(13,22,29,0.89)", //设置背景图片 rgba格式
              //     borderColor:'rgba(255, 255, 255, 0.49)',
              //     // borderColor:'rgba(199,199,199,0.9)',
              //     textStyle:{
              //         align:'left'
              //     },
              //     formatter: '<span style="color:#c4c9ca">采集时间：{b}<span><br/><span style="color:#c4c9ca">当 前 值：{c0}'+unit+'<span><br/>',
              // },
              dataZoom: [
                {
                  type: "slider",
                  show: true,
                  startValue: 0,
                  endValue: 5,
                  height: "20",
                  bottom: "0%",
                  // zoomLock: true,
                  start: 0, //数据窗口范围的起始百分比,表示30%
                  end: 100,
                  dataBackground: {
                    //数据阴影的样式。
                    // lineStyle:'#fffff',              //阴影的线条样式
                    // areaStyle:'#122757',              //阴影的填充样式
                  },
                  // fillerColor:"rgba(223, 254, 87, 0.19)",  //选中范围的填充颜色
                  // backgroundColor: 'rgba(37, 255, 149, 0.1)',
                  fillerColor: "rgba(39, 114, 240, 0.05)", //选中范围的填充颜色
                  textStyle: {
                    color: "#E4F0F2"
                  },
                  handleColor: "rgba(255, 254, 255, 0.50)", //滑块两端的线
                  borderColor: "rgba(0,0,0,0)" //边框
                  // handleIcon: require("../images/alone/zuobianjie_tuodong_kuang.png")
                }
              ],

              legend: {
                data: name,
                icon: "roundRect", //rect ，roundRect
                // bottom:0,
                x: "right", //left
                y: "top", //top
                padding: [10, 14, 0, 0],
                // top:'center',
                itemWidth: 20,
                itemHeight: 2,
                textStyle: {
                  // color: "#ffffff"
                  color: "#fff"
                }
                //  selected: {
                //     '最小值': false,
                //   }
              },

              tooltip: {
                trigger: "axis",
                //  axisPointer: {
                //      type: 'cross',
                //     label: {
                //         backgroundColor: '#6a7985'
                //     }
                // },
                // trigger: "axis",
                borderWidth: "1",
                // borderColor:'#fff',
                // textStyle:{
                //     align:'left'
                // },
                // formatter: '{b}'+'<br/>'+'{a0}: {c0} '+'<br/>'+'{a1}: {c1} ',
                // formatter: '{b}'+'<br/>',
                formatter(d) {
                  console.log("ddddd", d);
                  let html = "";
                  for (let i = 0; i < d.length; i++) {
                    if (i == 0) {
                      if (d[i].value) {
                        html += `<div>纵坐标：${d[i].name}</div><p style="color:${d[i].color}">${d[i].marker} ${d[i].seriesName}：${d[i].value}</p>`;
                      }
                    } else {
                      if (d[i].value) {
                        html += `<p style="color:${d[i].color};text-align: left;">${d[i].marker} ${d[i].seriesName}：${d[i].value}</p>`;
                      }
                    }
                  }
                  return html;
                }
              },

              xAxis: {
                type: "category", //
                data: time,
                axisLine: {
                  show: true,
                  lineStyle: {
                    // color: "#FBFBFB",
                    // width: 1 //这里是为了突出显示加上的
                    color: "#1c2b51",
                    type: "dashed"
                  }
                },
                axisTick: {
                  //y轴刻度线
                  show: false
                },
                axisLabel: {
                  color: "#fff",

                  //日期倾斜
                  // interval:100,    //显示间隔
                  // rotate: -20,
                  textStyle: {
                    // color: '#000',
                    fontSize: "10"
                    // itemSize:''
                  },
                  formatter: function (value) {
                    return value.split(" ").join("\n");
                  }
                }
              },

              yAxis: [
                {
                  scale: true, //y轴不已0为基础显示
                  type: "value",
                  // name : '单位: 次',
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      // 使用深浅的间隔色
                      // color: ["#9D9D9D"] //横线
                      color: "#1c2b51"
                    }
                  },
                  nameTextStyle: {
                    // color: ["#FBFBFB"] //单位的颜色
                    color: "#1c2b51"
                  },
                  axisLine: {
                    show: false, //true false
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  },
                  axisLabel: {
                    //  y轴左侧的文字
                    color: "#58637f",
                    fontSize: 14
                  },
                  axisTick: {
                    //y轴刻度线
                    show: false
                  }
                },
                {
                  // name: text1 + '(' + unit1 + ')',
                  nameTextStyle: {
                    padding: [0, 0, 0, -40] // 四个数字分别为上右下左与原位置距离
                  },
                  scale: true,
                  splitLine: {
                    show: false,
                    lineStyle: {
                      color: ["#a8b3ba"],
                      width: 1,
                      type: "solid"
                    }
                  },
                  axisLine: {
                    //y轴颜色
                    lineStyle: {
                      // color: "#fff",
                      color: "rgba(220,220,220,0)",
                      width: 1
                    }
                  },
                  axisLabel: {
                    //y轴数字颜色
                    show: true,
                    color: "#1c2b51",
                    textStyle: {
                      // color: "#fff"
                      // color: "rgba(220,220,220,0)"
                      color: "#1c2b51"
                    },
                    formatter: function (value, index) {
                      return value.toFixed(1);
                    }
                  }
                }
                // {
                //     axisLine:{
                //         show:true,
                //         lineStyle:{
                //             color:'#FBFBFB',
                //             width:1,//这里是为了突出显示加上的
                //         }
                //     }
                // },
              ],
              series: [
                // 应力
                {
                  // markLine: {
                  //   symbol: "none", //去掉警戒线最后面的箭头
                  //   label: {
                  //     formatter: "一级警告",
                  //     position: "end" //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                  //   },
                  //   data
                  // },
                  data: data1,
                  name: name[0],
                  type: "line",
                  showSymbol: false, //是否显示原点
                  smooth: true, //开启光滑
                  // symbol: 'circle', //设定为实心点
                  // symbolSize: 10, //设定实心点的大小
                  itemStyle: {
                    normal: {
                      color: "#2772F0", // 小圆点颜色
                      lineStyle: {
                        width: 2,
                        type: "solid", //'dotted'虚线 'solid'实线
                        color: "#2772F0" //线条颜色
                      }
                    }
                  }
                  // areaStyle: {
                  //   normal: {
                  //     //右，下，左，上
                  //     color: new echarts.graphic.LinearGradient(
                  //       0,
                  //       0,
                  //       0,
                  //       1,
                  //       [
                  //         {
                  //           offset: 0,
                  //           color: "#122757"
                  //         },
                  //         {
                  //           offset: 1,
                  //           // color:'rgba(255,255,255, 0)'
                  //           color: "#122757"
                  //         }
                  //       ],
                  //       false
                  //     )
                  //   }
                  // }
                },
                // 应力变化量
                {
                  data: data2,
                  name: name[1],
                  type: "line",
                  showSymbol: false,
                  smooth: true,
                  itemStyle: {
                    normal: {
                      color: "#F7B500", //小圆点颜色
                      lineStyle: {
                        width: 2,
                        type: "dashed", //'dotted'虚线 'solid'实线
                        color: "#F7B500" //线条颜色
                      }
                    }
                  }
                  // areaStyle: {
                  //   normal: {
                  //     //右，下，左，上
                  //     color: new echarts.graphic.LinearGradient(
                  //       0,
                  //       0,
                  //       0,
                  //       1,
                  //       [
                  //         {
                  //           offset: 0,
                  //           color: "#353f4e"
                  //         },
                  //         {
                  //           offset: 1,
                  //           color: "#353f4e"
                  //         }
                  //       ],
                  //       false
                  //     )
                  //   }
                  // }
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          moreLine1: function (id, time, data, name, unit) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            const optionData = {
              grid: {
                top: "10%",
                left: "2%",
                right: "0.5%",
                bottom: "14%",
                containLabel: true
              },
              // tooltip : {       //鼠标移入一条竖线   悬浮提示框样式
              //     trigger: "axis",
              //     borderWidth:'1',
              //     backgroundColor: "rgba(13,22,29,0.89)", //设置背景图片 rgba格式
              //     borderColor:'rgba(255, 255, 255, 0.49)',
              //     // borderColor:'rgba(199,199,199,0.9)',
              //     textStyle:{
              //         align:'left'
              //     },
              //     formatter: '<span style="color:#c4c9ca">采集时间：{b}<span><br/><span style="color:#c4c9ca">当 前 值：{c0}'+unit+'<span><br/>',
              // },
              dataZoom: [
                {
                  type: "slider",
                  show: true,
                  startValue: 0,
                  endValue: 5,
                  height: "40",
                  bottom: "0%",
                  // zoomLock: true,
                  start: 0, //数据窗口范围的起始百分比,表示30%
                  end: 100,
                  dataBackground: {
                    //数据阴影的样式。
                    // lineStyle:'#fffff',              //阴影的线条样式
                    // areaStyle:'#122757',              //阴影的填充样式
                  },
                  // fillerColor:"rgba(223, 254, 87, 0.19)",  //选中范围的填充颜色
                  // backgroundColor: 'rgba(37, 255, 149, 0.1)',
                  fillerColor: "rgba(39, 114, 240, 0.05)", //选中范围的填充颜色
                  textStyle: {
                    color: "#E4F0F2"
                  },
                  handleColor: "rgba(255, 254, 255, 0.50)", //滑块两端的线
                  borderColor: "rgba(0,0,0,0)" //边框
                  // handleIcon: require("../images/alone/zuobianjie_tuodong_kuang.png")
                }
              ],

              tooltip: {
                trigger: "axis",
                //  axisPointer: {
                //      type: 'cross',
                //     label: {
                //         backgroundColor: '#6a7985'
                //     }
                // },
                // trigger: "axis",
                borderWidth: "1"
                // borderColor:'#fff',
                // textStyle:{
                //     align:'left'
                // },
                // formatter: '{b}'+'<br/>'+'{a0}: {c0} '+'<br/>'+'{a1}: {c1} ',
                // formatter: '{b}'+'<br/>',
              },
              color: ["#32C5FF", "#0068F7", "#462AC5", "#CEA22B", "#5BD6AB", "#8464E0", "#A8E799", "#55A5FF", "#8BFFF1"],
              legend: {
                data: name,
                // bottom:0,
                // x : 左（left）、右（right）、居中（center）
                // y : 上（top）、下（bottom）、居中（center）
                // padding:[0,30,0,0] [（上），（右）、（下）、（左）]
                // top:'center',
                x: "center",
                // padding:[0,0,300,0],
                // itemWidth:80,
                itemGap: 50, //每项的间距
                // textStyle: {
                //     color: "#ffffff",
                //     width: 200
                // },
                textStyle: {
                  //图例字体大小
                  fontSize: 10,
                  color: "#fff"
                },
                itemHeight: 10,

                selected: {
                  最小值: false
                }
              },

              xAxis: {
                type: "category", //
                boundaryGap: true,
                data: time,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#FBFBFB",
                    width: 1 //这里是为了突出显示加上的
                  }
                },
                axisTick: {
                  //y轴刻度线
                  show: false
                },
                axisLabel: {
                  //日期倾斜
                  // interval:100,    //显示间隔
                  rotate: -20,
                  margin: 20, 
                  textStyle: {
                    // color: '#000',
                    fontSize: "10"
                    // itemSize:''
                  },
                  formatter: function (value) {
                    return value.split(" ").join("\n");
                  }
                }
              },

              yAxis: [
                {
                  scale: true, //y轴不已0为基础显示
                  type: "value",
                  // name : '单位: 次',
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      // 使用深浅的间隔色
                      color: ["#9D9D9D"] //横线
                    }
                  },
                  nameTextStyle: {
                    color: ["#FBFBFB"] //单位的颜色
                  },
                  axisLine: {
                    show: false, //true false
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  },
                  axisTick: {
                    //y轴刻度线
                    show: false
                  }
                }
                // {
                //     // name: text1 + '(' + unit1 + ')',
                //     nameTextStyle: {
                //         padding: [0, 0, 0, -40]    // 四个数字分别为上右下左与原位置距离
                //     },
                //     scale: true,
                //     splitLine: {
                //         show: false,
                //         lineStyle: {
                //             color: ['#a8b3ba'],
                //             width: 1,
                //             type: 'solid'
                //         }
                //     },
                //     axisLine: { //y轴颜色
                //         lineStyle: {
                //             // color: "#fff",
                //             color:'rgba(220,220,220,0)',
                //             width: 1
                //         }
                //     },
                //     axisLabel: { //y轴数字颜色
                //         show: true,
                //         textStyle: {
                //             // color: "#fff"
                //             color:'rgba(220,220,220,0)',
                //         },
                //         formatter: function(value, index) {
                //             return value.toFixed(1);
                //         }
                //     },
                // },
                // {
                //     axisLine:{
                //         show:true,
                //         lineStyle:{
                //             color:'#FBFBFB',
                //             width:1,//这里是为了突出显示加上的
                //         }
                //     }
                // },
              ],
              series: data
            };

            //     this.chart.setOption(optionData);
            //     window.addEventListener("resize", () => {
            //     this.chart.resize();
            // });

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });

            window.onresize = () => {
              this.chart.resize();
            };
            return this.chart;
          },
          moreLine2: function (id, time, seriesData, legendName) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            const optionData = {
              grid: {
                top: "10%",
                left: "2%",
                right: "0.5%",
                bottom: "44",
                containLabel: true
              },
              // tooltip : {       //鼠标移入一条竖线   悬浮提示框样式
              //     trigger: "axis",
              //     borderWidth:'1',
              //     backgroundColor: "rgba(13,22,29,0.89)", //设置背景图片 rgba格式
              //     borderColor:'rgba(255, 255, 255, 0.49)',
              //     // borderColor:'rgba(199,199,199,0.9)',
              //     textStyle:{
              //         align:'left'
              //     },
              //     formatter: '<span style="color:#c4c9ca">采集时间：{b}<span><br/><span style="color:#c4c9ca">当 前 值：{c0}'+unit+'<span><br/>',
              // },
              dataZoom: [
                {
                  type: "slider",
                  show: true,
                  startValue: 0,
                  endValue: 5,
                  height: "40",
                  bottom: "0%",
                  // zoomLock: true,
                  start: 0, //数据窗口范围的起始百分比,表示30%
                  end: 100,
                  dataBackground: {
                    //数据阴影的样式。
                    // lineStyle:'#fffff',              //阴影的线条样式
                    // areaStyle:'#122757',              //阴影的填充样式
                  },
                  // fillerColor:"rgba(223, 254, 87, 0.19)",  //选中范围的填充颜色
                  // backgroundColor: 'rgba(37, 255, 149, 0.1)',
                  fillerColor: "rgba(39, 114, 240, 0.05)", //选中范围的填充颜色
                  textStyle: {
                    color: "#E4F0F2"
                  },
                  handleColor: "rgba(255, 254, 255, 0.50)", //滑块两端的线
                  borderColor: "rgba(0,0,0,0)" //边框
                  // handleIcon: require("../images/alone/zuobianjie_tuodong_kuang.png")
                }
              ],

              tooltip: {
                trigger: "axis",
                //  axisPointer: {
                //      type: 'cross',
                //     label: {
                //         backgroundColor: '#6a7985'
                //     }
                // },
                // trigger: "axis",
                borderWidth: "1"
                // borderColor:'#fff',
                // textStyle:{
                //     align:'left'
                // },
                // formatter: '{b}'+'<br/>'+'{a0}: {c0} '+'<br/>'+'{a1}: {c1} ',
                // formatter: '{b}'+'<br/>',
              },
              color: ["#32C5FF", "#0068F7", "#462AC5", "#CEA22B", "#5BD6AB", "#8464E0", "#A8E799", "#55A5FF", "#8BFFF1"],
              legend: {
                data: legendName,
                // bottom:0,
                // x : 左（left）、右（right）、居中（center）
                // y : 上（top）、下（bottom）、居中（center）
                // padding:[0,30,0,0] [（上），（右）、（下）、（左）]
                // top:'center',
                x: "left",
                // padding:[0,0,300,0],
                // itemWidth:80,
                itemGap: 50, //每项的间距
                // textStyle: {
                //     color: "#ffffff",
                //     width: 200
                // },
                textStyle: {
                  //图例字体大小
                  fontSize: 10,
                  color: "#fff"
                },
                itemHeight: 10,

                selected: {
                  最小值: false
                }
              },

              xAxis: {
                data: time,
                type: "category", //
                boundaryGap: true,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#FBFBFB",
                    width: 1 //这里是为了突出显示加上的
                  }
                },
                axisTick: {
                  //y轴刻度线
                  show: false
                },
                axisLabel: {
                  //日期倾斜
                  // interval:100,    //显示间隔
                  rotate: -20,
                  textStyle: {
                    // color: '#000',
                    fontSize: "10"
                    // itemSize:''
                  },
                  formatter: function (value) {
                    return value.split(" ").join("\n");
                  }
                }
              },

              yAxis: [
                {
                  scale: true, //y轴不已0为基础显示
                  type: "value",
                  // name : '单位: 次',
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      // 使用深浅的间隔色
                      color: ["#9D9D9D"] //横线
                    }
                  },
                  nameTextStyle: {
                    color: ["#FBFBFB"] //单位的颜色
                  },
                  axisLine: {
                    show: false, //true false
                    lineStyle: {
                      color: "#FBFBFB",
                      width: 1 //这里是为了突出显示加上的
                    }
                  },
                  axisTick: {
                    //y轴刻度线
                    show: false
                  }
                }
                // {
                //     // name: text1 + '(' + unit1 + ')',
                //     nameTextStyle: {
                //         padding: [0, 0, 0, -40]    // 四个数字分别为上右下左与原位置距离
                //     },
                //     scale: true,
                //     splitLine: {
                //         show: false,
                //         lineStyle: {
                //             color: ['#a8b3ba'],
                //             width: 1,
                //             type: 'solid'
                //         }
                //     },
                //     axisLine: { //y轴颜色
                //         lineStyle: {
                //             // color: "#fff",
                //             color:'rgba(220,220,220,0)',
                //             width: 1
                //         }
                //     },
                //     axisLabel: { //y轴数字颜色
                //         show: true,
                //         textStyle: {
                //             // color: "#fff"
                //             color:'rgba(220,220,220,0)',
                //         },
                //         formatter: function(value, index) {
                //             return value.toFixed(1);
                //         }
                //     },
                // },
                // {
                //     axisLine:{
                //         show:true,
                //         lineStyle:{
                //             color:'#FBFBFB',
                //             width:1,//这里是为了突出显示加上的
                //         }
                //     }
                // },
              ],
              series: seriesData
            };

            //     this.chart.setOption(optionData);
            //     window.addEventListener("resize", () => {
            //     this.chart.resize();
            // });

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });

            window.onresize = () => {
              this.chart.resize();
            };
            return this.chart;
          },

          // 线形监测高程曲线图 xy轴不显示 背景虚线化 实测实线 目标虚线
          solidAndDashedLines: function (id, legendData, xData, seriesData, xName, yName) {
            let option = {
              tooltip: {
                trigger: "axis",
                show: true,
                formatter(d) {
                  console.log("ddddd", d);
                  let html = "";
                  for (let i = 0; i < d.length; i++) {
                    if (i == 0) {
                      if (d[i].value) {
                        html += `<div>纵坐标：${d[i].name}</div><p style="color:${d[i].color}">${d[i].marker} ${d[i].seriesName}：${d[i].value}</p>`;
                      }
                    } else {
                      if (d[i].value) {
                        html += `<p style="color:${d[i].color};text-align: left;">${d[i].marker} ${d[i].seriesName}：${d[i].value}</p>`;
                      }
                    }
                  }
                  return html;
                }
              },
              // color: ["#2073EF", "#FFC46F", "#7EBFF9", "#EFFF9E", "#32C5FF", "#0068F7", "#CEA22B", "#5BD6AB", "#8464E0", "#A8E799", "#55A5FF", "#8BFFF1"],
              color: ["#3E61CF", "#86AEF8", "#A7C446", "#EDFDA6", "#D2A350", "#FDCC73", "#6D6ADE", "#BBBEFE", "#34B6E4", "#97DEFA", "#44CAA9", "#97FDE6"],
              legend: {
                data: legendData,
                padding: [0, 15, 8, 0],
                textStyle: {
                  fontSize: 10,
                  lineHeight: 12,
                  color: "#ffffff"
                },
                itemWidth: 18,
                itemHeight: 8,
                y: "top",
                right: "30"
                // itemGap: 11
              },
              grid: {
                // top: "70px",
                left: "3%",
                right: "3%",
                bottom: "11.5%",
                containLabel: true
              },
              xAxis: {
                name: xName,
                type: "category",
                boundaryGap: false,
                data: xData,
                axisLine: {
                  show: false,
                  lineStyle: {
                    type: "solid",
                    color: "#fff" //左边线的颜色
                    // width: "2" //坐标线的宽度
                  }
                },
                nameTextStyle: {
                  align: "right",
                  padding: [73, 0, 0, 3]
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  textStyle: {
                    color: "#fff" //坐标值得具体的颜色
                  }
                }
              },
              yAxis: {
                name: yName,
                type: "value",
                scale: true,
                axisLine: {
                  show: false,
                  lineStyle: {
                    // type: "solid",
                    color: "#fff" //左边线的颜色
                    // width: "2" //坐标线的宽度
                  }
                },
                splitLine: {
                  show: true,
                  interval: 1,
                  lineStyle: {
                    type: "dashed",
                    color: "#fff",
                    opacity: "0.1"
                  }
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  textStyle: {
                    color: "#fff" //坐标值得具体的颜色
                  }
                }
              },
              series: seriesData
            };
            document.getElementById(id).removeAttribute("_echarts_instance_");
            let myChart = echarts.init(document.getElementById(id));
            myChart.setOption(option);
          },

          ring: function (id, color, num, num1) {
            var chartData = "88.23";
            // let chart = echarts.init(this.$refs.chartContent);
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            var data = {
              value: num1,
              company: "年度能耗",
              ringColor: [
                {
                  offset: 0,
                  color: color[0] // 0% 处的颜色   //ffc787    ffe1c0
                },
                {
                  offset: 1,
                  color: color[1] // 100% 处的颜色
                }
              ]
            };

            const optionData = {
              //   title: {
              //     text: data.value +'\n'+ data.company,
              //     x: 'center',
              //     y: 'center',
              //     textStyle: {
              //       color: '#fff',
              //       fontSize: '18'
              //     }
              //   },
              color: ["#c4b5a0"], //环形背景颜色
              legend: {
                show: false,
                data: []
              },
              graphic: {
                type: "text",
                left: "center",
                top: "43%",
                style: {
                  text: num, //num
                  textAlign: "center",
                  fill: "#fff",
                  fontSize: 14,
                  fontWeight: 400
                }
              },

              series: [
                {
                  name: "Line 1",
                  type: "pie",
                  clockWise: true,
                  radius: ["35", "50"], //设置圆环的半径
                  startAngle: -90, //起始角度
                  itemStyle: {
                    normal: {
                      label: {
                        show: false
                      },
                      labelLine: {
                        show: false
                      }
                    }
                  },
                  hoverAnimation: false,
                  data: [
                    {
                      value: data.value,
                      name: "",
                      itemStyle: {
                        normal: {
                          color: {
                            // 完成的圆环的颜色
                            colorStops: data.ringColor
                          },
                          label: {
                            show: false
                          },
                          labelLine: {
                            show: false
                          }
                        }
                      }
                    },
                    {
                      name: "",
                      value: 100 - data.value
                    }
                  ]
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          alongRing: function (id, color, num, num1,bg) {
            var chartData = "88.23";
            // let chart = echarts.init(this.$refs.chartContent);
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            var data = {
              value: num1,
              company: "年度能耗",
              ringColor: [
                {
                  offset: 0,
                  color: color[0] // 0% 处的颜色   //ffc787    ffe1c0
                },
                {
                  offset: 1,
                  color: color[1] // 100% 处的颜色
                }
              ]
            };

            const optionData = {
              //   title: {
              //     text: data.value +'\n'+ data.company,
              //     x: 'center',
              //     y: 'center',
              //     textStyle: {
              //       color: '#fff',
              //       fontSize: '18'
              //     }
              //   },
              tooltip: {
                show: false  // 将 show 属性设置为 false 关闭 tooltip
              },
              color: [bg], //环形背景颜色
              legend: {
                show: false,
                data: []
              },
              graphic: {
                type: "text",
                left: "center",
                top: "43%",
                style: {
                  text: num, //num
                  textAlign: "center",
                  fill: "#fff",
                  fontSize: 17,
                  fontWeight: 700
                }
              },

              series: [
                {
                  name: "Line 1",
                  type: "pie",
                  clockWise: true,
                  radius: ["35", "50"], //设置圆环的半径
                  startAngle: -90, //起始角度
                  silent:'ture',
                  // emphasis: {
                  //   disabled:false,
                  // scale:false,
                  // scaleSize:0,
                  // cursor: 'pointer'
                  // },
                  
                  itemStyle: {
                    normal: {
                      label: {
                        show: false
                      },
                      labelLine: {
                        show: false
                      }
                    },
                  },
                  hoverAnimation: true,
                  data: [
                    {
                      value: data.value,
                      name: "",
                      itemStyle: {
                        normal: {
                          color: {
                            // 完成的圆环的颜色
                            colorStops: data.ringColor
                          },
                          label: {
                            show: false
                          },
                          labelLine: {
                            show: false
                          }
                        }
                      }
                    },
                    {
                      name: "",
                      value: 100 - data.value
                    }
                  ]
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },

          // 一层环 不闭合
          dashboardRing11: function (id, color1, color2, num, percentNum) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let optionData = {
              title: [
                {
                  bottom: "200",
                  x: "center",
                  borderColor: "#fff",

                  padding: [7, 14],
                  textStyle: {
                    fontWeight: "normal",
                    fontSize: 12,
                    color: "#fff"
                  }
                }
              ],
              angleAxis: {
                show: false,
                max: (100 * 360) / 270, //-45度到225度，二者偏移值是270度除360度
                type: "value",
                startAngle: 225, //极坐标初始角度
                splitLine: {
                  show: false
                }
              },
              barMaxWidth: 14, //圆环宽度
              radiusAxis: {
                show: false,
                type: "category"
              },
              //圆环位置和大小
              polar: {
                center: ["50%", "50%"],
                radius: "80"
              },
              series: [
                {
                  type: "bar",
                  data: [
                    {
                      //上层圆环，显示数据
                      value: percentNum,
                      itemStyle: {
                        color: {
                          //图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
                          type: "linear",
                          x: 0,
                          y: 0,
                          x2: 1, //从左到右 0-1
                          y2: 0,
                          colorStops: [
                            {
                              offset: 0,
                              color: color1
                            },
                            {
                              offset: 1,
                              color: color2
                            }
                          ]
                        }
                      }
                    }
                  ],
                  barGap: "-100%", //柱间距离,上下两层圆环重合
                  coordinateSystem: "polar",
                  roundCap: true, //顶端圆角从 v4.5.0 开始支持
                  z: 2 //圆环层级，同zindex
                },
                {
                  //下层圆环，显示最大值
                  type: "bar",
                  data: [
                    {
                      value: 100,
                      itemStyle: {
                        color: "#324D93"
                      }
                    }
                  ],
                  barGap: "-100%",
                  coordinateSystem: "polar",
                  roundCap: true,
                  z: 1
                },
                //仪表盘
                {
                  name: "AQI",
                  type: "gauge",
                  startAngle: 225, //起始角度，同极坐标
                  endAngle: -45, //终止角度，同极坐标
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  },
                  axisTick: {
                    show: false
                  },
                  axisLabel: {
                    show: false
                  },
                  splitLabel: {
                    show: false
                  },
                  pointer: {
                    show: false
                  },
                  detail: {
                    show: false
                  },
                  title: {
                    offsetCenter: [0, 0],
                    color: "#FFFFFF",
                    fontSize: 16,
                    fontWeight: 500,
                    rich: {
                      a: {
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#FFF",
                        padding: [0, 0, 10, 40]
                      }
                    }
                  },

                  data: [
                    {
                      value: percentNum,
                      name: num
                    }
                  ]
                }
              ]
            };
            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          //一层环 闭合
          dashboardRing22: function (id, color1, color2, num, percentNum) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let optionData = {
              title: [
                {
                  bottom: "200",
                  x: "center",
                  borderColor: "#fff",

                  padding: [7, 14],
                  textStyle: {
                    fontWeight: "normal",
                    fontSize: 12,
                    color: "#fff"
                  }
                }
              ],
              angleAxis: {
                show: false,
                max: (100 * 360) / 360, //-45度到225度，二者偏移值是270度除360度
                type: "value",
                startAngle: 275, //极坐标初始角度
                splitLine: {
                  show: false
                }
              },
              barMaxWidth: 14, //圆环宽度
              radiusAxis: {
                show: false,
                type: "category"
              },
              //圆环位置和大小
              polar: {
                center: ["50%", "50%"],
                radius: "80"
              },
              series: [
                {
                  type: "bar",
                  data: [
                    {
                      //上层圆环，显示数据
                      value: percentNum,
                      itemStyle: {
                        color: {
                          //图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
                          type: "linear",
                          x: 0,
                          y: 0,
                          x2: 1, //从左到右 0-1
                          y2: 0,
                          colorStops: [
                            {
                              offset: 0,
                              color: color1
                            },
                            {
                              offset: 1,
                              color: color2
                            }
                          ]
                        }
                      }
                    }
                  ],
                  barGap: "-100%", //柱间距离,上下两层圆环重合
                  coordinateSystem: "polar",
                  roundCap: true, //顶端圆角从 v4.5.0 开始支持
                  z: 2 //圆环层级，同zindex
                },
                {
                  //下层圆环，显示最大值
                  type: "bar",
                  data: [
                    {
                      value: 100,
                      itemStyle: {
                        color: "#324D93"
                      }
                    }
                  ],
                  barGap: "-100%",
                  coordinateSystem: "polar",
                  roundCap: true,
                  z: 1
                },
                //仪表盘
                {
                  name: "AQI",
                  type: "gauge",
                  startAngle: 225, //起始角度，同极坐标
                  endAngle: -45, //终止角度，同极坐标
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  },
                  axisTick: {
                    show: false
                  },
                  axisLabel: {
                    show: false
                  },
                  splitLabel: {
                    show: false
                  },
                  pointer: {
                    show: false
                  },
                  detail: {
                    show: false
                  },
                  title: {
                    offsetCenter: [0, 0],
                    color: "#FFFFFF",
                    fontSize: 16,
                    fontWeight: 500,
                    rich: {
                      a: {
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#FFF",
                        padding: [0, 0, 10, 40]
                      }
                    }
                  },

                  data: [
                    {
                      value: percentNum,
                      name: num
                    }
                  ]
                }
              ]
            };
            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },

          // 施工总览 施工进度 好几层环
          dashboardRing: function (id, color1, color2, num, percentNum) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let optionData = {
              title: [
                {
                  text: percentNum,
                  x: "center",
                  top: "45%",
                  textStyle: {
                    fontSize: "24",
                    color: "#1DF7FF",
                    fontFamily: "Source Han Sans CN",
                    foontWeight: "500"
                  }
                }
              ],
              // backgroundColor: '#0E2E68',
              polar: {
                radius: ["80%", "60%"],
                center: ["50%", "50%"]
              },
              angleAxis: {
                max: 100,
                show: false
              },
              radiusAxis: {
                type: "category",
                show: true,
                axisLabel: {
                  show: false
                },
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                }
              },
              series: [
                {
                  // 渐变圈
                  name: "",
                  type: "bar",
                  radius: "95%",
                  roundCap: true,
                  barWidth: 90,
                  showBackground: true,
                  backgroundStyle: {
                    color: "#324D93"
                  },
                  data: [num],
                  coordinateSystem: "polar",

                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                        {
                          offset: 0,
                          color: color1
                          // color: '#fbc292' // 自带颜色
                          // color: '#85D8FD', // 蓝色风格
                        },
                        {
                          offset: 1,
                          color: color2
                          // color: '#06fbfe'
                          // color: '#298CEE',
                        }
                      ])
                    }
                  }
                },
                {
                  // 刻度
                  name: "",
                  type: "gauge",
                  splitNumber: 80, //刻度数量
                  radius: "95%", //图表尺寸
                  center: ["50%", "50%"],
                  startAngle: 90,
                  endAngle: -269.9999,
                  axisLine: {
                    show: false,
                    lineStyle: {
                      width: 0,
                      shadowBlur: 0,
                      color: [[1, "#0dc2fe"]]
                    }
                  },
                  axisTick: {
                    show: false,
                    lineStyle: {
                      color: "auto",
                      width: 2
                    },
                    length: 20,
                    splitNumber: 5
                  },
                  splitLine: {
                    show: true,
                    length: 5,
                    lineStyle: {
                      color: "auto"
                    }
                  },
                  axisLabel: {
                    show: false
                  },
                  pointer: {
                    //仪表盘指针
                    show: 0
                  },
                  detail: {
                    show: 0
                  }
                },
                {
                  // 亮线
                  name: "",
                  z: 5,
                  type: "pie",
                  cursor: "default",
                  radius: ["50%", "50%"],
                  hoverAnimation: false,
                  legendHoverLink: false,
                  labelLine: {
                    normal: {
                      show: false
                    }
                  },
                  data: [
                    {
                      value: 1,
                      itemStyle: {
                        normal: {
                          borderWidth: 2,
                          borderColor: {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                              {
                                offset: 0,
                                color: "#15B5FF"
                              },
                              {
                                offset: 0.6,
                                color: "rgba(2,144,214,0)"
                              }
                            ],
                            globalCoord: false
                          }
                        }
                      }
                    }
                  ]
                },
                {
                  //内圆
                  type: "pie",
                  radius: "100%",
                  center: ["80%", "80%"],
                  z: 1,
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.RadialGradient(
                        0.5,
                        0.5,
                        0.8,
                        [
                          {
                            offset: 0,
                            color: "#4978EC"
                          },
                          {
                            offset: 0.5,
                            color: "#1E2B57"
                          },
                          {
                            offset: 1,
                            color: "#141F3D"
                          }
                        ],
                        false
                      ),
                      label: {
                        show: false
                      },
                      labelLine: {
                        show: false
                      }
                    }
                  },
                  hoverAnimation: false,
                  label: {
                    show: false
                  },
                  tooltip: {
                    show: false
                  }
                }
              ]
            };
            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          // 横向柱形
          column: function (id) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();

            let dataAxis = ["点", "击", "柱", "子", "或", "者", "两", "指", "在", "触", "屏", "上", "滑", "动", "能", "够", "自", "动", "缩", "放"];
            // prettier-ignore
            let data = [220, 182, 191, 234, 290, 330, 310, 123, 442, 321, 90, 149, 210, 122, 133, 334, 198, 123, 125, 220];
            let yMax = 500;
            let dataShadow = [];
            for (let i = 0; i < data.length; i++) {
              dataShadow.push(yMax);
            }
            const option = {
              // title: {
              //     text: '特性示例：渐变色 阴影 点击缩放',
              //     subtext: 'Feature Sample: Gradient Color, Shadow, Click Zoom'
              // },
              xAxis: {
                data: dataAxis,
                axisLabel: {
                  inside: false, //x轴文字在柱形内部还是外部显示 true， false
                  color: "#fff"
                },
                axisTick: {
                  show: false
                },
                axisLine: {
                  show: false
                },
                z: 10
              },
              yAxis: {
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  color: "#999"
                }
              },
              dataZoom: [
                {
                  type: "inside"
                }
              ],
              series: [
                {
                  type: "bar",
                  showBackground: true,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#83bff6" },
                      { offset: 0.5, color: "#188df0" },
                      { offset: 1, color: "#188df0" }
                    ])
                  },
                  emphasis: {
                    itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: "#2378f7" },
                        { offset: 0.7, color: "#2378f7" },
                        { offset: 1, color: "#83bff6" }
                      ])
                    }
                  },
                  data: data
                }
              ]
            };
            // Enable data zoom when user click bar.
            const zoomSize = 6;
            this.chart.on("click", function (params) {
              console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
              this.chart.dispatchAction({
                type: "dataZoom",
                startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
                endValue: dataAxis[Math.min(params.dataIndex + zoomSize / 2, data.length - 1)]
              });
            });

            this.chart.setOption(option);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          // 堆叠柱状图  竖向
          columnar: function (id, month, bdci, sbci, spci, total, show) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();

            const option = {
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow"
                },
                formatter: (params) => {
                  let val = params[0].name;
                  let arr = ["background: linear-gradient(180deg, #76A9FF 0%, #4168DB 100%)", "background: linear-gradient(180deg, #2EAE68 0%, #186B3D 100%)", "background: linear-gradient(180deg, #5E50DA 0%, #7F4500 100%)"];
                  for (let i = 0; i < params.length - 1; i++) {
                    let str = params[i].marker.replace(/background-color:\[object Object\]/g, arr[i]);
                    val += "<br />" + str + params[i].seriesName + ":" + params[i].value + "分";
                  }

                  return val;
                }
              },
              legend: {
                selectedMode: false,
                data: ["上部结构SPCI", "下部结构SBCI", "桥面系BDCI"],
                textStyle: {
                  fontSize: 10,
                  fontFamily: "PingFangSC-Regular, PingFang SC",
                  fontWeight: 400,
                  color: "#fff"
                },
                itemWidth: 4,
                itemHeight: 8,
                itemGap: 30,
                padding: [0, 20, 0, 0],
                // orient: 'vertical',
                x: "right"
                //     selected:{
                //     '总计':false
                // }
              },
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
              },
              xAxis: [
                {
                  type: "category",
                  data: month,
                  axisLabel: {
                    inside: false, //x轴文字在柱形内部还是外部显示 true， false
                    color: "#8A94A6",

                    fontSize: 12
                  },
                  axisTick: {
                    show: false
                  },
                  axisLine: {
                    show: false,
                    lineStyle: {
                      color: "#1c2b51",
                      type: "dashed"
                    }
                  }
                }
              ],
              yAxis: [
                {
                  type: "value",
                  // data:[0,30,70,100],
                  max: 100,
                  axisLabel: {
                    inside: false, //x轴文字在柱形内部还是外部显示 true， false
                    color: "#58637f",
                    fontSize: 12
                  },
                  axisTick: {
                    show: false,
                    lineStyle: {
                      color: "#9D9D9D"
                    }
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: "dashed",
                      color: "#1c2b51"
                    }
                  }
                }
              ],
              series: [
                {
                  name: "上部结构SPCI",
                  type: "bar",
                  barWidth: 8,
                  stack: "Search Engine",

                  emphasis: {
                    focus: "series"
                  },
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#76A9FF" },
                      { offset: 1, color: "#4168DB" }
                    ])
                  },
                  emphasis: {
                    itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: "#4168DB" },
                        { offset: 1, color: "#76A9FF" }
                      ])
                    }
                  },
                  data: spci
                },
                {
                  name: "下部结构SBCI",
                  type: "bar",
                  stack: "Search Engine",
                  emphasis: {
                    focus: "series"
                  },
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#2EAE68" },
                      { offset: 1, color: "#186B3D" }
                    ])
                  },
                  emphasis: {
                    itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: "#186B3D" },
                        { offset: 1, color: "#2EAE68" }
                      ])
                    }
                  },
                  data: sbci
                },
                {
                  name: "桥面系BDCI",
                  type: "bar",
                  stack: "Search Engine",
                  emphasis: {
                    focus: "series"
                  },
                  itemStyle: {
                    normal: {
                      //柱形图圆角，初始化效果
                      barBorderRadius: [10, 10, 0, 0],
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: "#5E50DA" },
                        { offset: 1, color: "#7F4500" }
                      ])
                    }
                  },
                  emphasis: {
                    itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: "#7F4500" },
                        { offset: 1, color: "#5E50DA" }
                      ])
                    }
                  },
                  data: bdci
                },
                {
                  //新的一个柱子 注意不设stack
                  name: "总计",
                  type: "line",
                  // smooth: true,
                  barWidth: 8,
                  label: {
                    normal: {
                      show: true,
                      position: "top",

                      color: "#fff",
                      formatter: (a, b, c) => {
                        return a.value + "分";
                      }
                    }
                  },
                  data: total,
                  itemStyle: {
                    normal: {
                      //   color: 'rgba(128, 128, 128, 0)',
                    }
                  },
                  lineStyle: {
                    normal: {
                      width: 1.5,
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "rgba(220, 255, 164, 1)"
                          },

                          {
                            offset: 1,
                            color: "rgba(84, 251, 205, 1)"
                          }
                        ],
                        false
                      )
                    }
                  },
                  z: -1,
                  //不同系列的柱间距离，为百分比,如果想要两个系列的柱子重叠，可以设置 barGap 为 '-100%'。
                  barGap: "-100%"
                }
              ]
            };

            // var series = option['series'];
            // var fun = (params) => {
            //     var data3 = 0;
            //     for (let i = 0, n = series.length; i < n; i++){
            //         console.log('series[i].data[params.dataIndex][1]',series[i].data[params.dataIndex][1],series[i].data[params.dataIndex])
            //         data3+=series[i].data[params.dataIndex][1]
            //     }
            //     return data3
            // }

            // series[series.length-1]["label"]["normal"]["formatter"]=fun

            this.chart.setOption(option);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          // 状况评估-历史评分
          healthHis: function (id, month, health, regular) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            let option = {
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                  crossStyle: {
                    color: "#999"
                  }
                }
              },

              legend: {
                selectedMode: false,
                data: ["健康监测", "定期检查"],
                textStyle: {
                  fontSize: 10,
                  fontFamily: "PingFangSC-Regular, PingFang SC",
                  fontWeight: 400,
                  color: "#fff"
                },
                itemWidth: 4,
                itemHeight: 8,
                itemGap: 30,
                padding: [0, 20, 0, 0],
                // orient: 'vertical',
                x: "right"
                //     selected:{
                //     '总计':false
                // }
              },
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
              },
              xAxis: [
                {
                  type: "category",
                  data: month,
                  axisLabel: {
                    inside: false, //x轴文字在柱形内部还是外部显示 true， false
                    color: "#8A94A6",

                    fontSize: 12
                  },
                  axisTick: {
                    show: false
                  },
                  axisLine: {
                    show: false,
                    lineStyle: {
                      color: "#1c2b51",
                      type: "dashed"
                    }
                  }
                }
              ],
              yAxis: [
                {
                  type: "value",
                  // data:[0,30,70,100],
                  max: 100,
                  axisLabel: {
                    inside: false, //x轴文字在柱形内部还是外部显示 true， false
                    color: "#58637f",
                    fontSize: 12,
                    formatter: "{value} 分"
                  },
                  axisTick: {
                    show: false,
                    lineStyle: {
                      color: "#9D9D9D"
                    }
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: "dashed",
                      color: "#1c2b51"
                    }
                  }
                }
              ],
              series: [
                {
                  name: "健康监测",
                  type: "bar",
                  barWidth: 4,
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + " 分";
                    }
                  },
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#76A9FF" },
                      { offset: 1, color: "#4168DB" }
                    ])
                  },
                  emphasis: {
                    itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: "#4168DB" },
                        { offset: 1, color: "#76A9FF" }
                      ])
                    }
                  },
                  data: health
                },

                {
                  //新的一个柱子 注意不设stack
                  name: "定期检查",
                  type: "line",
                  // smooth: true,
                  barWidth: 8,
                  step: "end",
                  label: {
                    normal: {
                      show: true,
                      position: "top",
                      color: "#fff",
                      formatter: (a, b, c) => {
                        return a.value + "分";
                      }
                    }
                  },
                  data: regular,
                  itemStyle: {
                    normal: {
                      //   color: 'rgba(128, 128, 128, 0)',
                    }
                  },
                  lineStyle: {
                    normal: {
                      width: 1.5,
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "rgba(220, 255, 164, 1)"
                          },

                          {
                            offset: 1,
                            color: "rgba(84, 251, 205, 1)"
                          }
                        ],
                        false
                      )
                    }
                  },
                  z: -1,
                  //不同系列的柱间距离，为百分比,如果想要两个系列的柱子重叠，可以设置 barGap 为 '-100%'。
                  barGap: "-100%"
                }
              ]
            };

            this.chart.setOption(option);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },

          //  健康评估  饼图
          healthyBar: function (id, name, data) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            console.log("name", name, "data", data);
            var option = {
              legend: {
                top: "bottom",
                show: false
              },
              tooltip: {
                show: true
              },
              //   toolbox: {
              //     show: true,
              //     feature: {
              //       mark: { show: true },
              //       dataView: { show: true, readOnly: false },
              //       restore: { show: true },
              //       saveAsImage: { show: true }
              //     }
              //   },
              series: [
                {
                  name,
                  type: "pie",
                  radius: [40, 90],
                  center: ["50%", "50%"],
                  roseType: "area",
                  itemStyle: {
                    borderRadius: 0
                  },
                  data,
                  itemStyle: {
                    emphasis: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)"
                    }
                  }
                }
              ]
            };

            this.chart.setOption(option);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          tenanceBar: function (id, name, data) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();

            var option = {
              legend: {
                top: "bottom",
                show: false
              },
              tooltip: {
                show: true
              },
              series: [
                {
                  name,
                  type: "pie",
                  radius: [40, 90],
                  center: ["50%", "50%"],
                  roseType: "area",
                  itemStyle: {
                    borderRadius: 0
                  },
                  data,
                  itemStyle: {
                    emphasis: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)"
                    }
                  }
                }
              ]
            };

            this.chart.setOption(option);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          lineDouble: function (id, xarr, yName, series, lengData) {
            console.log("lineDouble", id, "xarr", xarr, "yname", yName, "serices", series, "lengdata", lengData);
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            const lineOption = {
              title: {
                text: ""
              },
              tooltip: {
                // 鼠标移入一条竖线   悬浮提示框样式
                trigger: "axis",
                borderWidth: "1",
                // backgroundColor: 'rgba(13,22,29,0.89)', // 设置背景图片 rgba格式
                // borderColor: 'rgba(255, 255, 255, 0.49)',
                // borderColor:'rgba(199,199,199,0.9)',
                textStyle: {
                  align: "left"
                }
                // formatter: '<span style="color:#c4c9ca">采集时间：{b}<span><br/><span style="color:#c4c9ca">当 前 值：{c0}' + unit + '<span><br/>'
              },
              animation: false,
              legend: {
                data: lengData,
                selected: [],
                left: "right",
                textStyle: {
                  color: "#ffffff"
                }
              },
              dataZoom: [
                {
                  // 默认为滚动条型数据区域缩放组件
                  show: true,
                  start: 1,
                  realtime: true,
                  end: 100,
                  zoomLock: false,
                  height: "15",
                  bottom: "0",
                  borderColor: "rgba(52, 134, 245, 0.5)"
                }
              ],
              xAxis: {
                type: "time",
                data: xarr,
                axisLabel: {
                  color: "#B5D2FF"
                  // interval: 0 // 全部显示不间隔,
                },
                /* 改变xy轴颜色 */
                axisLine: {
                  lineStyle: {
                    color: "#9AC1FF",
                    width: 1 // 这里是为了突出显示加上的
                  }
                },
                // 栅格设置
                splitLine: {
                  show: false,
                  lineStyle: {
                    type: "dashed",
                    color: "#ECF0FD",
                    // color: '#52A8FF',
                    opacity: "0.5"
                  }
                }
              },
              yAxis: [
                {
                  name: yName,
                  type: "value",
                  min: function (value) {
                    if (value.max >= 0 && value.min >= 0) {
                      // console.log('ssss')
                      return 0;
                    } else if (value.max >= 0 && value.min < 0) {
                      return (value.min * 3).toFixed(3);
                    } else if (value.max <= 0 && value.min <= 0) {
                      return (value.min * 3).toFixed(3);
                    }
                  },
                  max: function (value) {
                    if (value.max >= 0 && value.min >= 0) {
                      return (value.max * 3).toFixed(3);
                    } else if (value.max >= 0 && value.min < 0) {
                      return (value.max * 3).toFixed(3);
                    } else if (value.max <= 0 && value.min <= 0) {
                      return 0;
                    }
                  },
                  axisLabel: {
                    formatter: function (value, index) {
                      return value;
                    },
                    color: "#B5D2FF",
                    textStyle: {
                      fontSize: "10"
                    }
                  },
                  splitLine: {
                    show: true,
                    // show: false,
                    lineStyle: {
                      type: "dashed",
                      color: "#ECF0FD",
                      opacity: "0.5"
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: "#9AC1FF",
                      width: 1
                    }
                  }
                },
                {
                  name: "",
                  type: "value",
                  min: function (value) {
                    if (value.max >= 0 && value.min >= 0) {
                      return 0;
                    } else if (value.max >= 0 && value.min < 0) {
                      return (value.min * 3).toFixed(3);
                    } else if (value.max <= 0 && value.min <= 0) {
                      return (value.min * 3).toFixed(3);
                    }
                  },
                  max: function (value) {
                    if (value.max >= 0 && value.min >= 0) {
                      return (value.max * 3).toFixed(3);
                    } else if (value.max >= 0 && value.min < 0) {
                      return (value.max * 3).toFixed(3);
                    } else if (value.max <= 0 && value.min <= 0) {
                      return 0;
                    }
                  },
                  axisLabel: {
                    formatter: function (value, index) {
                      return value;
                    },
                    color: "#B5D2FF",
                    textStyle: {
                      fontSize: "10"
                    }
                  },
                  splitLine: {
                    show: true,
                    // show: true,
                    lineStyle: {
                      type: "dashed",
                      color: "#ECF0FD",
                      opacity: "0.5"
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: "#9AC1FF",
                      width: 1
                    }
                  }
                }
              ],
              series,
              // series: [
              //   {
              //     name: 'Mon',
              //     data: [150, 230, 224, 218, 135, 147, 260],
              //     connectNulls:true,
              //     type: 'line',
              //     itemStyle : {
              //       normal : {
              //           lineStyle:{
              //               color:''
              //           }
              //       }
              //   },
              //   },
              //   {
              //     name: 'Tue',
              //     data: [150, 230, 224, 222, 135, 147, 260],
              //     connectNulls:true,
              //     type: 'line',
              //     itemStyle : {
              //       normal : {
              //           lineStyle:{
              //               color:''
              //           }
              //       }
              //   },
              //   }
              // markLine : {
              //   symbol:"none",
              //   /*symbol:"none",               //去掉警戒线最后面的箭头
              //   label:{
              //       position:"end"   ,       //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
              //   },*/
              //   data : [{
              //       silent:true,             //鼠标悬停事件  true没有，false有
              //       lineStyle:{               //警戒线的样式  ，虚实  颜色
              //           type:"dashed",    //dashed  solid
              //           color:"red",    //  rgba(255,184,13,0.6)
              //           width:10,
              //       },
              //       label:{
              //         show:false,
              //         padding: [-13, -20, 45, -45],
              //         position:'end',
              //         formatter: function(value) {
              //           // 确保你的Number.toFixed(2)是满足要求的
              //           return value.value.toFixed(2);
              //         }
              //       },
              //       yAxis: 200   // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
              //     }
              //   ]
              // },
              // ],
              color: ["rgba(0, 105, 255, 1)", "#F7CC19", "#FF7F00"],
              grid: {
                top: "12%",
                left: "5%",
                right: "4%",
                bottom: "15%",
                backgroundColor: "transparent",
                containLabel: false
              }
            };
            this.chart.setOption(lineOption);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          }
        };
      }
    }
  });
};

export default {
  install
};
