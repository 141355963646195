<template>
  <!-- register 注册 -->
  <div class="register">
    <div class="loginBox">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <!-- 用户名 -->
        <el-form-item prop="username" class="mb">
          <el-input type="text" prefix-icon="el-icon-user-solid" v-model.trim="ruleForm.username" autocomplete="off" placeholder="请输入用户名"> </el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password" class="mb">
          <el-input type="password" prefix-icon="el-icon-s-goods" v-model.trim="ruleForm.password" placeholder="请输入密码" autocomplete="off"> </el-input>
        </el-form-item>
        <!-- 确认密码 -->
        <!-- <el-form-item prop="checkPass">
                    <el-input type="password" 
                        prefix-icon="el-icon-s-goods"
                        v-model.trim="ruleForm.checkPass" 
                        auto-complete="off" 
                        placeholder="确认密码">
                    </el-input>
                </el-form-item> -->
        <!-- 公司 company-->
        <el-form-item prop="">
          <el-input type="text" prefix-icon="el-icon-s-tools" v-model.trim="ruleForm.company" autocomplete="off" placeholder="请输入公司名称"> </el-input>
        </el-form-item>
        <!-- 电话 -->
        <el-form-item prop="tel" class="mb">
          <el-input v-model.trim="ruleForm.tel" prefix-icon="el-icon-phone" auto-complete="off" placeholder="请输入手机号"> </el-input>
        </el-form-item>
        <!-- 验证码 -->
        <el-form-item prop="smscode" class="code" style="margin-bottom: 29px !important">
          <el-input prefix-icon="el-icon-s-grid" v-model.trim="ruleForm.smscode" placeholder="验证码"></el-input>
          <!-- <div class="codeZi">{{buttonText}}</div> -->
          <el-button type="primary" class="codeZi" :disabled="isDisabled" @click="sendCode">{{ buttonText }} </el-button>
        </el-form-item>

        <el-form-item>
          <el-button class="loginBtn" type="primary" @click="goLogin('ruleForm')">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "sign",
  data() {
    // 用户名
    var checkData = (rule, value, callback) => {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          console.log("校验");
          callback("不能输入中文");
        } else if (value.length < 6 || value.length > 18) {
          callback("6-18位英文/数字/字符组成");
        } else {
          callback();
        }
      }
      callback();
    };
    // 用户名
    var checkData1 = (rule, value, callback) => {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          console.log("校验");
          callback("不能输入中文");
        } else if (value.length < 6 || value.length > 18) {
          callback("6-18位英文/数字/字符组成");
        } else {
          callback();
        }
      }
      callback();
    };

    // 验证密码
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    // 二次验证密码
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    // 验证手机号是否合法
    let checkTel = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (!this.checkMobile(value)) {
        callback(new Error("手机号码不合法"));
      } else {
        callback();
      }
    };

    // 验证码是否为空
    let checkSmscode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机验证码"));
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        username: "", //用户名
        password: "", //密码
        // checkPass: "",  //确认密码
        tel: "", //电话
        smscode: "", //验证码
        company: "" //公司
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { validator: checkData, trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: checkData1, trigger: "change" }
        ],
        // checkPass: [
        //     { validator: validatePass2, trigger: 'change' }
        // ],
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkTel, trigger: "change" }
        ],
        smscode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { validator: checkSmscode, trigger: "change" }
        ],
        company: [{ required: true, message: "请输入公司", trigger: "blur" }]
      },
      buttonText: "发送验证码",
      isDisabled: false, // 是否禁止点击发送验证码按钮
      flag: true
    };
  },
  //创建
  created() {},

  //安装
  mounted() {},

  //方法
  methods: {
    //点击登录
    goLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("验证成功");
          this.regUser(); //注册
        } else {
          console.log("注册失败!!");
          return false;
        }
      });
    },

    // 注册用户
    regUser() {
      var pwd = this.ruleForm.password;
      var param = new URLSearchParams();
      param.append("username", this.ruleForm.tel); //电话
      // param.append("password", this.$md5(pwd))         //密码及加密
      param.append("password", this.ruleForm.password); //密码及加密 password
      param.append("realname", this.ruleForm.username); //用户名
      param.append("verifyCode", this.ruleForm.smscode); //验证码
      param.append("companyName", this.ruleForm.company); //公司

      this.$axios
        .post(`${this.baseURL}auth/security/register`, param)
        .then((res) => {
          console.log("注册返回", res);
          console.log(res.status);
          var res = res.data;

          if (res.status == "200") {
            this.$message.success("注册成功");
            this.$bus.$emit("successful", "success");
          }
          if (res.errCode == "-1006") {
            this.$message.error(res.errMsg);
          }
          if (res.errCode == "10101") {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {});
    },

    // 验证手机号
    checkMobile(str) {
      let re = /^1\d{10}$/;
      if (re.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    // <!--发送验证码-->
    sendCode() {
      let tel = this.ruleForm.tel;
      console.log("tel", tel);
      if (this.ruleForm.tel == "") {
        this.$message.error("请输入手机号");
        return;
      }
      if (this.checkMobile(tel)) {
        console.log(tel);
        let time = 60;
        this.buttonText = "已发送";
        this.isDisabled = true;
        this.sendOut(); // 发送验证码
        if (this.flag) {
          this.flag = false;
          let timer = setInterval(() => {
            time--;
            this.buttonText = time + " 秒";
            if (time === 0) {
              clearInterval(timer);
              this.buttonText = "重新获取";
              this.isDisabled = false;
              this.flag = true;
            }
          }, 1000);
        }
      }
    },

    // 发送验证码
    sendOut() {
      var phone = this.ruleForm.tel;
      var param = new URLSearchParams();
      param.append("phone", phone);
      this.$axios
        .post(`${this.baseURL}auth/security/verifyCode`, param)
        .then((res) => {
          console.log("获取验证码", res); //查看手机
          if (res.data.status != 200) {
            this.$message.error(res.data.errMsg);
          }
        })
        .catch((err) => {});
    },

    // 重置表单
    resetLoginForm() {
      this.$refs.ruleForm.resetFields();
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep button.el-button.is-disabled,
::v-deep button.el-button.is-disabled:focus,
::v-deep button.el-button.is-disabled:hover {
  background-color: #434f6a !important;
}

.register {
  // width: 100%;
  // height: 100%;
  position: relative;
}

.loginBox {
  position: absolute;
  // width: 300px;
  // right: 200px;
}

::v-deep .loginBox {
  .el-input {
    height: 28px !important;
  }

  .el-input__inner {
    line-height: 28px !important;
    height: 100%;
    border: 1px solid transparent !important;
    background-color: #f0f7ff !important;
    border-radius: 14px !important;
    padding-left: 54px !important;
    color: #000;
    font-size: 16px;
    // &:focus{
    //     border-color: #CDE200;
    //     &+.el-input__prefix{
    //     color:#CDE200;
    //     }
    // }
  }

  .el-form-item__error {
    color: red;
    padding-top: 2px;
    padding-left: 20px;
  }

  .el-input__icon {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 28px !important;

    &.el-icon-user-solid:before {
      content: "\e604"; //百度图库的16位  用户名图标
    }

    &.el-icon-s-goods:before {
      content: "\e603"; //百度图库的16位  密码图标
    }

    &.el-icon-s-tools:before {
      content: "\e602";
    }

    &.el-icon-phone:before {
      content: "\e601";
    }

    &.el-icon-s-grid:before {
      content: "\e605";
    }
  }

  .el-form-item__content {
    line-height: 28px;
  }

  .el-form-item {
    margin-bottom: 16px !important; //表格间隙
  }

  .el-input ::placeholder {
    font-size: 14px !important;
    color: #b0b2b6 !important;
    letter-spacing: 0 !important;
  }

  .el-checkbox__inner {
    width: 12px;
    height: 12px;
    border-color: #b0b2b6;
  }

  .el-checkbox__label {
    font-size: 12px;
    color: #b0b2b6;
  }

  .loginBtn {
    padding: 0;
    font-size: 16px;
    width: 100%;
    height: 37px;
    line-height: 37px;
    background: #1f61ff;
    border-radius: 19px;
    text-align: center;
    letter-spacing: 4px;
  }

  .codeZi {
    background: transparent;
    height: 28px;
    width: 80px;
  }
}

::v-deep .code {
  position: relative;

  .codeZi {
    position: absolute;
    font-size: 12px;
    top: 0;
    right: 35px;
    color: #1f61ff;
    letter-spacing: 0px;
    cursor: pointer;
  }

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    color: #1f61ff;
    background: transparent !important;
  }
}
</style>
