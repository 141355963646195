import { mapGetters, mapState } from "vuex";
import { ISOPATIONS } from "../store/const";
export const mixin = {
  data() {
    return {
      isOptionList: [],
      myCheck: false, //查看
      myOption: false, // 操作
      myUpload: false, // 上传
      myDel: false, // 删除
      myRelation: false, // 删除
      myDynamic: false, // 删除
      isOptions: []
      //   level   级别（1：查看；2：操作；3：上传；4：删除；5：关联； 6：动态）
      //  status   状态（0:未选；1：已选)
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.isOptionList = JSON.parse(sessionStorage.getItem(ISOPATIONS) || "[]");
      this.initOptions();
    });
    this.isOptions = (this.$store&&this.$store.getters.getIsOptions) || []
  },
  computed: {
    // ...mapGetters({
    //   isOptions: "getIsOptions" || []
    // }),
    // ...mapState({
    //   isOptionsArr: 'isOptions'
    // })
  },
  watch: {
    isOptions: {
      handler(n, o) {
        this.isOptionList = n;
        this.initOptions();
      },
      deep: true
    }
  },
  methods: {
    initOptions() {
      // console.log(...mapGetters([getIsOptions]))
      // console.log("methods==================权限列表设置", this.isOptions);
      this.myCheck = false;
      this.myOption = false;
      this.myUpload = false;
      this.myDel = false; // 删除
      this.myRelation = false; // 删除
      this.myDynamic = false; // 删除
      this.isOptionList.forEach((item) => {
        if (item.level == 1 && item.status == 1) {
          this.myCheck = true;
        }
        if (item.level == 2 && item.status == 1) {
          this.myOption = true;
        }
        if (item.level == 3 && item.status == 1) {
          this.myUpload = true;
        }
        if (item.level == 4 && item.status == 1) {
          this.myDel = true;
        }
        if (item.level == 5 && item.status == 1) {
          this.myRelation = true;
        }
        if (item.level == 6 && item.status == 1) {
          this.myDynamic = true;
        }
      });
    }
  }
};
////    :class="!myOption ? 'disable_icon' : ''"
