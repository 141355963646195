import Vue from "vue";
import Vuex from "vuex";
import { BREADCRUMB, ISOPATIONS } from "./const";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    name: "",
    name1: sessionStorage.getItem("name1") || "",
    name2: "",
    name3: "",
    projectId: sessionStorage.getItem("projectId") || "",
    projectName: sessionStorage.getItem("projectName") || "",
    projectCompay: sessionStorage.getItem("projectCompay") || "",
    breadcrumbList: JSON.parse(sessionStorage.getItem(BREADCRUMB) || "[]"),
    isOptions: JSON.parse(sessionStorage.getItem(ISOPATIONS) || "[]")
  },

  mutations: {
    // 发送桥梁名称
    getProjectName(state, load) {
      state.projectName = load;
    },
    // 桥梁公司
    getProjectCompay(state, load) {
      state.projectCompay = load;
    },

    getName(state, load) {
      state.name = load;
    },
    getName1(state, load) {
      state.name1 = load;
    },
    getName2(state, load) {
      state.name2 = load;
    },
    getName3(state, load) {
      state.name3 = load;
    },
    getProjectId(state, load) {
      state.projectId = load;
    },
    getBreadcrumb(state, load) {
      state.breadcrumbList = load;
    },
    getIsOptions(state, load) {
      console.log("load", load, "state", state);
      state.isOptions = load;
    }
  },
  getters: {
    getBreadcrumb(state) {
      return state.breadcrumbList || [];
    },
    getIsOptions(state) {
      console.log("权限江亭====state", state.isOptions);
      return state.isOptions || [];
    }
  }
});
