<template>
  <!-- 登录 -->
  <div class="sign">
    <div class="loginBox">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item prop="username" style="margin-bottom: 18px !important">
          <el-input type="text" prefix-icon="el-icon-user-solid" v-model="ruleForm.username" autocomplete="off" placeholder="请输入用户名"> </el-input>
        </el-form-item>
        <el-form-item prop="password" style="margin-bottom: 6px !important">
          <el-input type="password" prefix-icon="el-icon-s-goods" v-model="ruleForm.password" placeholder="请输入密码" autocomplete="off"> </el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 50px !important">
          <div class="box justify center" style="color: #b0b2b6">
            <el-checkbox v-model="checked" style="">记住密码</el-checkbox>
            <!-- <div class="fs12 pointer">忘记密码</div> -->
          </div>
        </el-form-item>

        <el-form-item style="margin-bottom: 10px !important">
          <div class="loginBtn pointer" @click="goLogin('ruleForm')">登录</div>
        </el-form-item>
        <el-form-item>
          <!-- <div class="textL reg">没有账号，立即注册</div>    -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { BREADCRUMB } from "@/store/const";
export default {
  name: "sign",
  data() {
    var checkData = (rule, value, callback) => {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          console.log("校验");
          callback("不能输入中文");
        } else if (value.length < 5 || value.length > 18) {
          callback("5-18位英文/数字/字符组成");
        } else {
          callback();
        }
      }
      callback();
    };
    var checkData1 = (rule, value, callback) => {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          console.log("校验");
          callback("不能输入中文");
        } else if (value.length < 6 || value.length > 18) {
          callback("6-18位英文/数字/字符组成");
        } else {
          callback();
        }
      }
      callback();
    };

    return {
      ruleForm: {
        username: "", //admin
        password: "", //666666
        mobile: "",
        verifycode: "",
        breadList: []
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { validator: checkData, trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: checkData1, trigger: "blur" }
          // { min: 6, max: 16, message: '密码由6-16位的字母数字下划线组成', trigger: "blur" },
        ]
      },
      checked: false //记住密码
    };
  },
  //创建
  created() {},

  //安装
  mounted() {
    // 读取记住密码
    this.getCookie();
  },

  //方法
  methods: {
    // 获取桥梁信息
    getBridge() {
      this.$axios.post(`${this.baseURL}pandect/select/likename?name=`).then((res) => {
        console.log("登录默认进入第一个权限导航,获取到的桥梁id------", res);
        if (res.data.data && res.data.data[0] && res.data.data[0].list) {
          sessionStorage.setItem("projectId", res.data.data[0].list[0].id);
        }
        this.getNav();
      });
    },
    // 获取一级导航
    getNav() {
      this.breadList = [];
      // this.$axios.get(`${this.baseURL}auth/authPermission/user/all/one`).then((res) => {
      this.$axios.get(`${this.baseURL}navigation/all/one`).then((res) => {
        console.log("一级导航-----", res);

        this.breadList.push(res.data.data[0]);
        if (!res.data.data.length) {
          this.$router.push("/empty");
        }
        if (res.data.data[0].path) {
          // this.getBridge();
          setTimeout(() => {
            sessionStorage.setItem(BREADCRUMB, JSON.stringify([res.data.data[0]]));
            this.$store.commit("getBreadcrumb", [res.data.data[0]]);
            this.$router.push(res.data.data[0].path);
          }, 100);
        } else {
          let id = res.data.data[0].id;
          // 获取二级导航 一级导航的第一个页面
          // this.$axios.get(`${this.baseURL}auth/authPermission/user/all/two/${id}`).then((res) => {
          this.$axios.get(`${this.baseURL}navigation/all/two/${id}`).then((res) => {
            console.log("二级导航----------", res);
            // this.getBridge();
            setTimeout(() => {
              if (this.breadList.length > 1) {
                this.breadList.splice(1);
              }
              if (this.breadList[0].name && this.breadList[0].name !== res.data.data[0].name) {
                this.breadList.push(res.data.data[0]);
              }

              sessionStorage.setItem(BREADCRUMB, JSON.stringify(this.breadList));
              this.$store.commit("getBreadcrumb", this.breadList);

              this.$router.push(res.data.data[0].path || "/empty");
            }, 100);
          });
        }
      });
    },
    goLogin(formName) {
      //点击登录
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("验证成功");
          this.httpLogin();
          console.log("保存记住密码", document.cookie); //获取   userName=admin1; userPwd=123456
        } else {
          console.log("注册失败!!");
          return false;
        }
      });
    },
    // 登录请求
    httpLogin() {
      var name = this.ruleForm.username;
      var pwd = this.ruleForm.password;
      console.log("name, pwd", name, pwd);
      var param = new URLSearchParams();
      param.append("username", name);
      // param.append("password", this.$md5(pwd))
      param.append("password", pwd);
      this.$axios
        .post(`${this.baseURL}auth/security/login`, param)
        .then((res) => {
          console.log("登录返回", res);
          var res = res.data;
          // console.log('res', res)
          //   console.log('status', res.status)

          if (res.status == "200") {
            sessionStorage.setItem("name", this.ruleForm.username);
            sessionStorage.setItem("userNameId", res.data.id);
            sessionStorage.setItem("realname", res.data.realname);
            var token = res.data.token;
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("userId", res.data.id); // 当前登录用户的id
            sessionStorage.setItem("isAdmin", res.data.isAdmin); //是否是管理员
            // sessionStorage.setItem('projectId',33);
            // sessionStorage.setItem('authority','guest');           // 判断是否是游客
            // this.$router.push('/allView');
            // this.$router.push("/main");
            // this.getNav();

            // 密码保存
            if (this.checked == true) {
              //传入账号名，密码，和保存天数3个参数
              this.setCookie(this.ruleForm.username, this.ruleForm.password, 7);
            } else {
              //清空Cookie
              this.clearCookie();
            }
            this.getBridge();
          } else if (res.status == "10100") {
            console.log("用户不存在");
            this.$message.error("用户不存在");
          } else if (res.status == "10110") {
            console.log("用户名或密码错误");
            this.$message.error("用户名或密码错误");
          } else {
            console.log("登录失败");
            this.$message.error("登录失败");
          }
        })
        .catch((err) => {});
    },

    //设置cookie  用户名，密码， 保存天数
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      window.document.cookie = "userName_qiaoling" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie = "userPwd_qiaoling" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
    },

    // 读取Cookie 种用户名，密码
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); //再次切割
          console.log("arr2", arr2);
          //判断查找相对应的值
          if (arr2[0] == "userName_qiaoling") {
            this.checked = true; //如果当时保存了，就代表需要勾选"记住密码"
            this.ruleForm.username = arr2[1]; //保存到保存数据的地方
          } else if (arr2[0] == "userPwd_qiaoling") {
            this.ruleForm.password = arr2[1];
          }
        }
      }
    },

    //清除cookie
    clearCookie() {
      this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    }
  }
};
</script>
<style scoped lang="scss">
.sign {
  // width: 100%;
  // height: 100%;
  position: relative;
}

.loginBox {
  position: absolute;
  // width: 300px;
  // right: 200px;
}

::v-deep .loginBox {
  .el-input {
    height: 28px !important;
  }
  .el-input__inner {
    line-height: 28px !important;
    height: 28px;
    border: 1px solid transparent !important;
    background-color: #f0f7ff !important;
    border-radius: 14px !important;
    padding-left: 54px !important;
    color: #000;
    font-size: 16px;
    // &:focus{
    //     border-color: #CDE200;
    //     &+.el-input__prefix{
    //     color:#CDE200;
    //     }
    // }
  }
  .el-form-item__error {
    color: red;
    padding-top: 2px;
    padding-left: 20px;
  }
  .el-input__icon {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 28px !important;
    &.el-icon-user-solid:before {
      content: "\e604"; //百度图库的16位  用户名图标
    }
    &.el-icon-s-goods:before {
      content: "\e603"; //百度图库的16位  密码图标
    }
  }
  .el-form-item__content {
    line-height: 28px;
  }
  .el-form-item {
    margin-bottom: 0px !important; //表格间隙
  }
  .el-input ::placeholder {
    font-size: 14px !important;
    color: #b0b2b6 !important;
    letter-spacing: 0 !important;
  }
  .el-checkbox__inner {
    width: 12px;
    height: 12px;
    border-color: #b0b2b6;
  }
  .el-checkbox__label {
    font-size: 12px;
    color: #b0b2b6;
  }
  .loginBtn {
    padding: 0;
    font-size: 16px;
    width: 100%;
    height: 37px;
    line-height: 37px;
    background: #1f61ff;
    border-radius: 19px;
    text-align: center;
    letter-spacing: 4px;
  }
  .reg {
    text-align: right;
    font-size: 12px;
    color: #1f61ff;
    letter-spacing: 2px !important;
  }
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #409eff !important;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #409eff !important;
  border-color: #409eff !important;
}
::v-deep .el-checkbox__inner:hover {
  border-color: #409eff !important;
}
::v-deep .el-checkbox__inner {
  border-color: #ccc !important;
}
</style>
