import qs from "qs";
import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/reset.css";
import "./assets/css/common.scss";
import "./assets/css/newStyle.scss";
import "./assets/icon/iconfont.css";
import "./assets/whole/iconfont.css";
import * as echarts from "echarts";
import ElementUI from "element-ui";
import tablePlugin from "v-fit-columns";
import filters from "./assets/js/filter";
import "element-ui/lib/theme-chalk/index.css";
import charts from "@/assets/echarts/echarts.js";
import charts2 from "@/assets/echarts/echarts2.js";
import charts3 from "@/assets/echarts/deviation.js";
import charts4 from "@/assets/echarts/deviation2.js";
import "../src/assets/css/font.scss";
import md5 from "js-md5";
import vueToPdf from 'vue-to-pdf';
import VueEasyPrinter from 'vue-easy-printer';
import "devextreme/dist/css/dx.common.css";
import "devexpress-gantt/dist/dx-gantt.css";
// import 'devextreme/dist/css/dx.dark.css'
import "devextreme/dist/css/dx.darkmoon.css";

import Login from "./login.js";
import { addColor } from "../addColor";
import { mixin } from "./mixin";

addColor();

// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";

Vue.prototype.$md5 = md5;
for (let key in filters) {
  Vue.filter(key, filters[key]);
}
Vue.prototype.$bus = new Vue();

Vue.prototype.$echarts = echarts;

ElementUI.Dialog.props.closeOnClickModal.default = false;

import Print from "./assets/js/print";
Vue.mixin(mixin);
Vue.use(Print);

Vue.use(charts);
Vue.use(charts2);
Vue.use(charts3);
Vue.use(charts4);
Vue.use(ElementUI);
Vue.use(tablePlugin);
Vue.use(vueToPdf);
Vue.use(VueEasyPrinter);

Vue.prototype.$qs = qs;
Vue.prototype.$axios = axios;

var host = window.location.host;

Vue.prototype.baseMQTT = "114.115.170.39"; //mqtt地址   原:117.78.51.17  演示:114.115.170.39:8197  
Vue.prototype.baseNumber = 8083; //mqtt端口号    8083  1883
Vue.prototype.mqttName = "hawkeye";
Vue.prototype.mqttPass = "Hawkeye1234";

// dev是测试环境，pre是生产环境
let mode = 'dev';
// if (mode == 'dev') {
if (false) {
  Vue.prototype.baseURL = "http://114.116.13.1:8197/";
  Vue.prototype.topic = "/bridge_v2_test/datas";
  Vue.prototype.srcPATH = "https://www.thingjs.com/pp/4ace010ee17c0cb185ee8dcd";
  // Vue.prototype.srcPATH = ''
  Vue.prototype.unityPATH = "http://webtest.cloudansys.com/bridge/static/Bridge_H/index.html";
  Vue.prototype.downloadURL = "http://114.116.13.1:8197/";
} else {
  // 演示环境
  // Vue.prototype.topic = "/bridge_v2/datas";
  // Vue.prototype.baseURL = "http://114.115.170.39:8197/";
  // Vue.prototype.srcPATH = "https://www.thingjs.com/pp/b11aea19e425d75509af50ad";
  // Vue.prototype.unityPATH = "http://bridge.ylsas.com/static/Bridge_H/index.html";
  // Vue.prototype.downloadURL = "http://114.115.170.39:8197";

  // pre环境
  Vue.prototype.topic = "/bridge_v2/datas";
  Vue.prototype.baseURL = "http://114.115.223.210:8197/";
  Vue.prototype.srcPATH = "https://www.thingjs.com/pp/b11aea19e425d75509af50ad";
  Vue.prototype.unityPATH = "http://bridge.ylsas.com/static/Bridge_H/index.html";
  Vue.prototype.downloadURL = "http://114.115.223.210:8197/";
}
// 实时数据 topic路径
// Vue.prototype.topic = "/bridge_v2/datas"; //正式
// Vue.prototype.topic = "/bridge_v2_test/datas"; //测试

// 后台服务
// Vue.prototype.baseURL = "http://114.116.93.53:8197/"; //生产环境接口  http://114.115.162.96:7070/        (测试环境)114.116.13.1
// Vue.prototype.baseURL = "http://114.116.13.1:8197/"; // 测试接口  二级路由
// Vue.prototype.baseURL = "http://10.10.0.74:8197/"; // 测试接口  二级路由春雨电脑测试
// Vue.prototype.baseURL =  'http://114.115.211.204:8197/'     //华为严选  二级路由

// gis路径生产/测试
// Vue.prototype.srcPATH = "https://www.thingjs.com/pp/b11aea19e425d75509af50ad"; //生产
// Vue.prototype.srcPATH = "https://www.thingjs.com/pp/4ace010ee17c0cb185ee8dcd"; //测试
// Vue.prototype.srcPATH = 'https://www.thingjs.com/pp/6ba70cced90806686d68545e'                 //华为严选

// unity 生产/测试
// Vue.prototype.unityPATH = "http://bridge.ylsas.com/static/Bridge_H/index.html"; //生产
// Vue.prototype.unityPATH = "http://webtest.cloudansys.com/bridge/static/Bridge_H/index.html"; //测试
// Vue.prototype.unityPATH = 'http://hwbcshow.cloudansys.com/bridge/static/Bridge_H/index.html';     //华为严选

// unity 配置项目
// StreamingAssets  下  替换ip地址    http://114.116.93.53:8197 (生产)   http://114.116.13.1:8197(测试)     http://114.115.211.204:8197(华为严选)

// 11.09
//http://114.116.93.53:8197/doc.html#/home 接口文档    192.168.16.181

// 下载地址
// Vue.prototype.downloadURL = 'http://bridgedownload.cloudansys.cn:7070/';
// Vue.prototype.downloadURL = "http://114.116.93.53:8197/"; //生产
// Vue.prototype.downloadURL = "http://114.116.13.1:8197/"; //测试
// Vue.prototype.downloadURL = 'http://114.115.211.204:8197/';           //华为严选

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

//拦截器  request请求拦截器
axios.interceptors.request.use((config) => {
  // console.log('config',config)
  // console.log(44)
  // config.headers.token = window.sessionStorage.getItem('token');   // config.headers.Authorization
  // return config;

  var str1 = "https://open.ys7.com/api/lapp/token/get?appKey=a2f0529eddfc4726bc46ab78b9b15609&appSecret=46f68bd6322b7ea2ac11b7843715c389";

  if (config.url == str1) {
    console.log("不需要token");
    return config;
  } else {
    config.headers.token = window.sessionStorage.getItem("token");
    return config;
  }
});

Vue.config.productionTip = false;

console.log("保存的token", sessionStorage.getItem("token"), window.location.href.includes("/Login"));

if (!sessionStorage.getItem("token") && !window.location.href.toLocaleLowerCase().includes("/login")) {
  new Login(Vue.prototype.baseURL).init().then((res) => {
    console.log("登录接口对象跳转", res);
    window.location.href = res;

    new Vue({
      router,
      store,
      render: (h) => h(App)
    }).$mount("#app");
    store.commit("getBreadcrumb", JSON.parse(sessionStorage.getItem("BREADCRUMB")));
    // if (res) {
    //     router.push(res)
    // } else {
    //     router.push('/home')
    // }
  });
} else {
  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount("#app");
}

// 代码提交测试分支
