const filters = {
  timeFilters(val){
    var arr = ['原始值','1分钟均值','10分钟均值','1小时均值','日均值']
    return arr[val]
  },

  statusData(val){
    // 0:故障 1:在线 2:离线
    var arr = ['故障','在线','离线']
    return arr[val];
  },


    lineShow(val){
      if(val == null){
        val = "-"
      }else{
       val = val.toFixed(3) 
      }
      return val
    },



    trueFalse (val) {
      const arr = ['否','是','-']
      if(val == null){
        val = '2'
      }
      return arr[val]
    },   


    showApprove (val) {
      const arr = ['环境类','效应类','荷载类']
      return arr[val]
    },   

    staticDynamic(val){
      const arr = ['','静态效应','动态效应','振动响应']
      return arr[val]
    },

    numTime(val){
      const arr = ['','10','20','30']
      return arr[val]
    },

    getDate (str) {
      let year = str.slice(0,4)
      let month = str.slice(4,6)
      let day = str.slice(6,8)
      let hh = str.slice(8,10)
      let mm = str.slice(10,12)
      let ss = str.slice(12,14)
      return year + '-' + month + '-' + day + ' ' + hh + ':'+ mm + ':'+ ss
    },


    // 时域分析处理时段长度   0: 其他，1: 1分钟，2: 5分钟，3: 10分钟，4: 15分钟，5: 20分钟，6: 1小时，7: 1天，8: 1月）
    timeDuration(val) {
      const arr = ['其他', '1分钟',  '5分钟', '10分钟', '15分钟', '20分钟', '1小时', ' 1天', '1月','—']
      if(val == null){
        val = '9'
      }
      if(val == 99){
        val = '9'
      }

      return arr[val]
  },
    //   数据分析-温度效应提取--修改显示逻辑
  timeDurationZhw(val) {
    // const arr = ['其他', '1分钟',  '5分钟', '10分钟', '15分钟', '20分钟', '1小时', ' 1天', '1月','—']
    const arr = ['其他', '3小时',  '6小时', '12小时','—']
    if(val == null){
      val = '4'
    }
    if(val == 99){
      val = '4'
    }

    return arr[val]
},

  // frequencyDuration 频域统计分析频率 （0: 其他，1: 1分钟，2: 5分钟，3: 10分钟，4: 15分钟，5: 20分钟，6: 0.5小时，7: 1小时，8: 1.5小时）
  frequencyDuration(val) {
      const arr = ['其他', '1分钟', '5分钟', '10分钟', '15分钟', '20分钟', '0.5小时', ' 1小时', '1.5小时','—']
      if(val == null){
        val = '9'
      }
      if(val == 99){
        val = '9'
      }
      return arr[val]
  },

  formatSolution(val){
      if(val == null){
        val ="-"
      }else{
        val = val
      }
   return val
  },

    checkRel(val){
      if(val == 1){
        val = true
      }else{
        val = false
      }
      return val
    },
    
    Duration(val) {
      const arr = ['其他', '1分钟',  '5分钟', '10分钟', '15分钟', '20分钟', '1小时', ' 1天', '1月','—']
      if(val == null){
        val = arr[9]
      }else{
        val = val +'秒'
      }
      if(val == 99){
        val = arr[9]
      }

      var val = val;

      return val;
  },






  }
  
  export default filters