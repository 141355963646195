import axios from "axios";
import ElementUI from "element-ui";
import { BREADCRUMB } from "@/store/const";
class Login {
  constructor(baseUrl) {
    // let url='http://badusanbridge.ylsas.com?token=eyJhbGciOiJIUzI1NiJ9.eyJaTGpRNmVCV3lhWGRLOCI6IjFhY2FlOWI2OWQ3NjhkN2Y1YjA1OGRlODIwOTIxNDVmIiwic3ViIjoi5ryU56S66LSm5Y-3IiwiZXhwIjoxNjYwMjA4MTE2LCJpYXQiOjE2NTg5OTg1MTYsImluZm8iOiJ7XCJzdGFuZGFyZFwiOlwiMTUxODg5NzQyMjkzMDAyMzA4MVwiLFwicHJlcGFyZVwiOlwiMjBcIixcImNvbnRyYWN0XCI6XCIxNzBcIixcImNoZWNrXCI6XCI4OTVcIixcImlyc1wiOlwiNTc1XCIsXCJtdGNfcmVwb3J0XCI6XCIyXCIsXCJxdWFsaXR5XCI6XCIzNjBcIixcIm1wc1wiOlwiNDQzXCIsXCJ3YWdlc1wiOlwiMjkxXCIsXCJxdWFsaXR5SUFcIjpcIjE2MzZcIixcInJlcG9ydFwiOlwiMTYxXCIsXCJzYWZlXCI6XCI2MDRcIixcImFjY291bnRcIjpcIua8lOekuui0puWPt1wifSJ9.Mo3_4R4mg2kpshPjbRATwPltwnD3oe8XttLvKvvrpaM'
    // let url='http://badusanbridge.ylsas.com'
    let path = window.location.href;
    console.log("==========-----4444", window.location);
    this.url = path.split("?")[0];
    if (path.toLowerCase().indexOf("token=") != -1) {
      this.token = path.split("token=")[1].split("&")[0];
    } else {
      this.token = null;
    }

    this.baseUrl = baseUrl;
    this.name = "beitou";
    this.pwd = "xinchuang123!";
    // this.url = 'http://badusanbridge.ylsas.com'
    console.log("token", this.token, "baseurl", this.baseUrl, "url", this.url, "name", this.name, "pwd", this.pwd);
    this.init();
  }
  resetLocation() {
    window.location.href = this.formatUrl("/Login");
  }
  rebackLocation() {
    window.history.back();
  }
  formatUrl(myback) {
    if (this.url.indexOf("#") === -1) {
      return this.url + myback;
    } else {
      return this.url.split("/#/")[0] + "/#" + myback;
    }
  }
  init() {
    return new Promise((resolve, reject) => {
      console.log("token", this.token);
      if (!this.token) {
        resolve(this.formatUrl("/Login"));
        return;
      }
      // resolve('请求成功')
      axios
        .get(`http://219.159.152.72:14107/rest/parserJwt?token=${this.token}`)
        .then((res) => {
          console.log("返回", res);
          if (res.data.code == 0) {
            this.httpLogin().then((myback) => {
              resolve(this.formatUrl(myback));
            });
          } else {
            // window.location.href='http://badusanbridge.ylsas.com'
            this.rebackLocation();
          }
        })
        .catch((error) => {
          console.log("错误", error);
          this.rebackLocation();

          // window.location.href='http://badusanbridge.ylsas.com'
        });
      // fetch(`http://219.159.152.72:14107/rest/parserJwt?token=${token}`).then(res => res.text()).then(ex => {
      //     console.log("验证token返回",ex)
      // })
    });
  }

  httpLogin() {
    return new Promise((resolve, reject) => {
      console.log("登录", this.baseUrl);
      var param = new URLSearchParams();
      param.append("username", this.name);
      // param.append("password", this.$md5(pwd))
      param.append("password", this.pwd);
      axios
        .post(`${this.baseUrl}auth/security/login`, param)
        .then((res) => {
          console.log("登录返回", res);
          var res = res.data;
          // console.log('res', res)
          //   console.log('status', res.status)

          if (res.status == "200") {
            sessionStorage.setItem("name", this.name);
            sessionStorage.setItem("userNameId",res.data.id)
            sessionStorage.setItem("realname", res.data.realname);
            var token = res.data.token;
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("isAdmin", res.data.isAdmin); //是否是管理员
            // sessionStorage.setItem('projectId',33);
            // sessionStorage.setItem('authority','guest');           // 判断是否是游客
            // this.$router.push('/allView');
            // this.$router.push("/main");
            console.log("getNav==========", this.getNav());
            // resolve(this.getNav())
            this.getNav()
              .then((response) => {
                console.log("========-----=====", response);
                resolve(response);
              })
              .catch((error) => {
                this.resetLocation();
              });
          } else if (res.status == "10100") {
            console.log("用户不存在");
            ElementUI.Message.error("用户不存在");
            this.resetLocation();
          } else if (res.status == "10110") {
            console.log("用户名或密码错误");
            ElementUI.Message.error("用户名或密码错误");
            this.resetLocation();
          } else {
            console.log("登录失败");
            ElementUI.Message.error("登录失败");
            this.resetLocation();
          }
        })
        .catch((err) => {
          this.resetLocation();
        });
    });
  }

  getBridge() {
    axios.post(`${this.baseUrl}pandect/select/likename?name=`).then((res) => {
      console.log("登录默认进入第一个权限导航,获取到的桥梁id------", res);
      if (res.data.data && res.data.data[0] && res.data.data[0].list) {
        sessionStorage.setItem("projectId", res.data.data[0].list[0].id);
      }
    });
  }
  // 获取一级导航
  getNav() {
    let breadList = [];
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.baseUrl}auth/authPermission/user/all/one`)
        .then((res) => {
          console.log("一级导航-----", res, "url", this.url);
          breadList.push(res.data.data[0]);
          if (res.data.data[0].uri) {
            this.getBridge();
            setTimeout(() => {
              sessionStorage.setItem(BREADCRUMB, JSON.stringify([res.data.data[0]]));
              // this.$store.commit("getBreadcrumb", [res.data.data[0]]);

              //   this.$router.push(res.data.data[0].uri)
              //   return res.data.data[0].uri
              resolve(res.data.data[0].uri);
            }, 100);
          } else {
            let id = res.data.data[0].id;
            // 获取二级导航 一级导航的第一个页面
            axios
              .get(`${this.baseUrl}auth/authPermission/user/all/two/${id}`)
              .then((res) => {
                console.log("二级导航----------", res);
                this.getBridge();
                setTimeout(() => {
                  if (breadList.length > 1) {
                    breadList.splice(1);
                  }
                  if (breadList[0].name !== res.data.data[0].name) {
                    breadList.push(res.data.data[0]);
                  }

                  sessionStorage.setItem(BREADCRUMB, JSON.stringify(breadList));
                  // this.$store.commit("getBreadcrumb", breadList);

                  // this.$router.push(res.data.data[0].path)
                  // return res.data.data[0].path
                  resolve(res.data.data[0].path);
                }, 100);
              })
              .catch((err) => {
                this.resetLocation();
              });
          }
        })
        .catch((err) => {
          this.resetLocation();
        });
    });
  }
}

export default Login;
