<template>
  <div id="app">
    <router-view />
    <!-- <div style="color: var(--mytest)">sssssss</div> -->
    <!-- <div style="width: 100px; height: 100px; border: 1px solid red; position: absolute; left: 0; top: 0; z-index: 99999" @click="test"></div> -->
  </div>
</template>
<script>
export default {
  created() {
    console.log(location);
  },
  methods: {
    test(e) {
      console.log("点击测试", e);
    }
  }
};
</script>
<style>
:root {
  --btnColor: #32c5ff;
  --fontcolor: #fff;
  --bgcolor: #fff;
  --font12: 12px;
  --font16: 16px;
  --titlcolor: #123f94;
  --labelcol: #999;
  --font14: 14px;
  --btnOptionColor: #ffffff;
  --tableTitleColor: #ffffff;
}

/* html {
  filter: grayscale(100%);
  filter: gray;
} */

.colorText {
  /* color: var(--btnColor); */
  color: var(--btnOptionColor);
}

.colorTable {
  color: var(--tableTitleColor);
}

.select-btn.el-button--primary {
  background-color: transparent;
  border: 1px solid #28a0d8;
  color: #1dc7fc;
}

#app .main ::v-deep .mlbtn.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  color: #1f61ff;
  background: transparent !important;
}

.checkHistory {
  display: inline-block;
  padding: 6px 28px;
  border-radius: 20px;
  color: #6d81bf;
  background: #233361;
}

.activeColorHis {
  display: inline-block;
  padding: 6px 28px;
  border-radius: 20px;
  color: #fff !important;
  background: #0050b4 !important;
}
/* 仿真页面样式 */
.info-res {
  position: absolute;
  right: 20px;
  height: 50px;
  min-width: 248px;
  /* background: url(./assets/images/cedianliebiao_bg@2x.png) no-repeat; */
  background-size: 100% auto;
  text-align: center;
  line-height: 50px;
  /* z-index: 999; */
  padding: 0 10px;
  background: rgba(5, 21, 52, 1);
  border: 1px solid rgba(136, 218, 254, 0.3);
  border-radius: 8px;
  user-select: none;
}

.reset-simulation-model {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  /* right: 340px;
  top: 55px; */
  right: 20px;
  bottom: 20px;
  width: 33px;
  height: 33px;
  background: #32456a url("./assets/images/all/locate-nosec.png") no-repeat;
  background-size: 17px 17px;
  background-position: center;
  cursor: pointer;
}
.reset-simulation-model:hover{
  position: absolute;
  border-radius: 50%;
  right: 20px;
  bottom: 20px;
  width: 33px;
  height: 33px;
  background: #1c21a1 url("./assets/images/all/locate.png") no-repeat;
  background-size: cover;

}
</style>
