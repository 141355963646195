/**
 * 偏差统计图表    2022-8-22 beta
 */

// import echarts from 'echarts'
import * as echarts from "echarts";
// import tuli01 from '../assets/img/tuli01.png';
// import tuli02 from '../assets/img/tuli02.png';

const dataZoom = [
  {
    show: true,
    realtime: true,
    start: 0,
    end: 100,
    textStyle: {
      color: "#58637f" //滚动条两边字体样式
    },
    fillerColor: "rgba(39, 114, 240, 0.2)", //选中范围的填充颜色
    handleColor: "#194fdb", //滑块两端的线
    borderColor: "rgba(255,255,255,0.00)" //边框
  }
  // {
  //     type: "inside",
  //     realtime: true,
  //     start: 0,
  //     end: 10
  // }
];

let areaStyle1 = {
  normal: {
    color: new echarts.graphic.LinearGradient(
      0,
      0,
      0,
      1,
      [
        {
          offset: 0,
          color: "rgba(39,114,240,0.50)"
        },
        {
          offset: 0.8,
          color: "rgba(39,114,240,0.00)"
        },
        {
          offset: 1,
          color: "rgba(39,114,240,0.00)"
        }
      ],
      false
    )
  }
};
let areaStyle2 = {
  normal: {
    color: new echarts.graphic.LinearGradient(
      0,
      0,
      0,
      1,
      [
        {
          offset: 0,
          color: "rgba(247,181,0,0.50)"
        },
        {
          offset: 0.8,
          color: "rgba(247,181,0,0.00)"
        },
        {
          offset: 1,
          color: "rgba(247,181,0,0.00)"
        }
      ],
      false
    )
  }
};

function formateDate(str) {
  if (!str.length && isNaN(str)) {
    let d = new Date();
    let s = d.getMonth() + 1 + "月" + d.getDate() + "日";
    return s;
  }
  let n = new Date(str);
  let m = n.getMonth() + 1 + "月" + n.getDate() + "日";

  return m;
}

const install = function (Vue) {
  Object.defineProperties(Vue.prototype, {
    $chart3: {
      get() {
        return {
          //  偏差趋势  d, xData, yData, name, lineColor,  max, min
          trend: function (id, time, xData, data1, data2, name) {
            this.chart = echarts.init(document.getElementById(id));
            console.log("图表进来trend");
            // this.chart.clear();
            // if(areaType==1){
            //     var areaStyle = areaStyle1
            // } else if(areaType==2){
            //     var areaStyle = areaStyle2
            // }else{
            //     var areaStyle = 1
            // }
            if (time.length == 0) {
              var titleShow = true;
            } else {
              var titleShow = false;
            }

            const optionData = {
              //   dataZoom: dataZoom,
              grid: {
                top: "35",
                left: "8%",
                right: "4%",
                bottom: "0%",
                containLabel: true
              },
              title: {
                // show: titleShow, // 是否显示title
                show: !xData.length && !data1.length && !data2.length,
                text: "暂无数据",
                top: "40",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 16,
                  fontWeight: 200
                }
              },
              tooltip: {
                trigger: "axis"
              },
              // legend: {
              //   data: ["自动化测点", "人工测点"],
              //   x: "right",
              //   y: "30px",
              //   textStyle: {
              //     color: ["red", "blue"]
              //   }
              // },
              xAxis: {
                type: "category",
                axisLabel: {
                  color: "#8A94A6",
                  // interval: Math.ceil(time.length / 6),
                  fontSize: 14,

                  formatter: function (value) {
                    // console.log("value数据过滤", value);
                    // return value.split(" ").join("\n");
                    return formateDate(value);
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: "#1c2b51",
                    type: "dashed"
                  }
                },
                axisTick: {
                  show: false
                },
                data: xData
              },
              yAxis: [
                {
                  type: "value",
                  name: "次",
                  nameTextStyle: {
                    color: "#58637f",
                    fontSize: 14
                  },
                  axisLabel: {
                    //  y轴左侧的文字
                    color: "#58637f",
                    fontSize: 14
                  },
                  axisLine: {
                    //   y轴的颜色
                    show: false,
                    lineStyle: {
                      color: "#9D9D9D"
                    }
                  },
                  splitLine: {
                    lineStyle: {
                      color: "#1c2b51",
                      type: "dashed"
                    }
                  },
                  axisTick: {
                    show: false
                  }
                }
                // {
                //   type: "value",
                //   axisLine: {
                //     lineStyle: {
                //       color: "#9D9D9D"
                //     }
                //   },
                //   axisTick: {
                //     show: false
                //   }
                // }
              ],
              series: [
                {
                  // name: "人工测点",
                  data: data1,
                  type: "bar",
                  symbol: "none",
                  barWidth: 27,
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "rgba(149, 254, 243, 1)"
                          },

                          {
                            offset: 1,
                            color: "rgba(26, 83, 116, 1)"
                          }
                        ],
                        false
                      )
                    },
                    barBorderRadius: 15
                  }
                },
                {
                  // name: "自动化测点",
                  type: "line",
                  data: data2,
                  smooth: true,
                  showSymbol: false,
                  symbol: "circle",
                  symbolSize: 5,
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "rgba(220, 255, 164, 1)"
                          },

                          {
                            offset: 1,
                            color: "rgba(84, 251, 205, 1)"
                          }
                        ],
                        false
                      )
                    }
                  },
                  lineStyle: {
                    normal: {
                      width: 1.5,
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "rgba(220, 255, 164, 1)"
                          },

                          {
                            offset: 1,
                            color: "rgba(84, 251, 205, 1)"
                          }
                        ],
                        false
                      )
                    }
                  }
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          // 频谱
          line2: function (id, time, data, name, lineColor, areaType, xname, name1, max, min) {
            this.chart = echarts.init(document.getElementById(id));
            // this.chart.clear();
            if (areaType == 1) {
              var areaStyle = areaStyle1;
            } else if (areaType == 2) {
              var areaStyle = areaStyle2;
            } else {
              var areaStyle = 1;
            }
            if (time.length == 0) {
              var titleShow = true;
            } else {
              var titleShow = false;
            }

            const optionData = {
              dataZoom: dataZoom,
              grid: {
                top: "20%",
                left: "8%",
                right: "4%",
                bottom: "20%",
                containLabel: true
              },
              title: {
                show: titleShow, // 是否显示title
                text: "暂无数据",
                top: "40",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 16,
                  fontWeight: 200
                }
              },
              tooltip: {
                trigger: "axis"
              },
              xAxis: {
                name: xname,
                nameTextStyle: {
                  // x轴name的样式调整
                  color: "#58637f"
                },
                nameRotate: 270,
                type: "category",
                axisLabel: {
                  color: "#cdd0d8",
                  interval: Math.ceil(time.length / 6),
                  fontSize: 12,
                  //   rotate: 4,
                  showMinLabel: true,
                  showMaxLabel: true,
                  //   showMinLabel: false,
                  formatter: function (value) {
                    return value.split(" ").join("\n");
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: "#1c2b51"
                    // type: "dashed"
                  }
                },
                axisTick: {
                  show: false
                },
                data: time
              },
              yAxis: [
                {
                  type: "value",
                  name: name,
                  nameTextStyle: {
                    color: "#58637f",
                    fontSize: 14
                  },
                  axisLabel: {
                    //  y轴左侧的文字
                    color: "#58637f",
                    fontSize: 14
                  },
                  axisLine: {
                    //   y轴的颜色
                    show: false,
                    lineStyle: {
                      color: "#9D9D9D"
                    }
                  },
                  splitLine: {
                    lineStyle: {
                      color: "#1c2b51",
                      type: "dashed"
                    }
                  },
                  axisTick: {
                    show: false
                  }
                }
                // {
                //   type: "value",
                //   axisLine: {
                //     lineStyle: {
                //       color: "#9D9D9D"
                //     }
                //   },
                //   axisTick: {
                //     show: false
                //   }
                // }
              ],
              series: [
                {
                  name: name1,
                  data: data,
                  type: "line",
                  symbol: "none",
                  areaStyle: areaStyle,
                  itemStyle: {
                    color: "#6DB5C7"
                  },
                  lineStyle: {
                    color: lineColor,
                    width: 2
                  }
                }
              ]
            };

            if (max) {
              optionData.yAxis[0].max = max;
              optionData.yAxis[0].min = min;
            }

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          deviationLine: function (id, obj, date) {
            this.chart = echarts.init(document.getElementById(id));
            let arr = ["rgba(2, 174, 147, 1)", "rgba(101, 255, 241, 1)"];
            let arr1 = ["rgba(220, 255, 164, 1)", "rgba(169, 255, 206, 1)"];
            // let arr = ["red", "blue"];
            // let arr1 = ["red", "blue"];
            const optionData = {
              //   dataZoom: dataZoom,
              grid: {
                top: "25",
                left: "8%",
                right: "4%",
                bottom: "10",
                containLabel: true
              },
              title: {
                // show: titleShow, // 是否显示title
                show: !obj.person.length && !obj.auto.length,
                text: "暂无数据",
                top: "10",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 16,
                  fontWeight: 200
                }
              },
              tooltip: {
                trigger: "axis"
              },
              // legend: {
              //   data: ["自动化测点", "人工测点"],
              //   x: "right",
              //   y: "30px",
              //   textStyle: {
              //     color: ["red", "blue"]
              //   }
              // },
              xAxis: {
                // type: "category",
                type: "time",
                // min: Math.min(...date),
                // max: Math.max(...date),
                // splitNumber: date.length,
                splitLine: {
                  show: false
                },

                axisLabel: {
                  color: "#8A94A6",
                  // interval: Math.ceil(time.length / 6),
                  fontSize: 12,
                  rotate: 30,
                  formatter: function (value) {
                    // return value.split(" ").join("\n");
                    console.log("pianchashiina", value);
                    return formateDate(value);
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: "#1c2b51",
                    type: "dashed"
                  }
                },
                axisTick: {
                  show: false
                }
                // data: obj.time
              },
              yAxis: [
                {
                  type: "value",
                  name: obj.unit,
                  nameTextStyle: {
                    color: "#58637f",
                    fontSize: 12
                  },
                  axisLabel: {
                    //  y轴左侧的文字
                    color: "#58637f",
                    fontSize: 12
                  },
                  axisLine: {
                    //   y轴的颜色
                    show: false,
                    lineStyle: {
                      color: "#9D9D9D"
                    }
                  },
                  splitLine: {
                    lineStyle: {
                      color: "#1c2b51",
                      type: "dashed"
                    }
                  },
                  axisTick: {
                    show: false
                  }
                }
                // {
                //   type: "value",
                //   axisLine: {
                //     lineStyle: {
                //       color: "#9D9D9D"
                //     }
                //   },
                //   axisTick: {
                //     show: false
                //   }
                // }
              ],
              series: [
                {
                  // name: "自动化测点",
                  type: "line",
                  data: obj.auto,
                  smooth: true,
                  symbol: "circle",
                  symbolSize: 4,
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            // color: obj.targetType == 1 ? arr[0] : arr1[0]
                            color: arr[0]
                          },

                          {
                            offset: 1,
                            // color: obj.targetType == 1 ? arr[1] : arr1[1]
                            color: arr[1]
                          }
                        ],
                        false
                      )
                    }
                  },
                  lineStyle: {
                    type: "solid",
                    width: 1.5,
                    color: {
                      colorStops: [
                        {
                          offset: 0,
                          // color: obj.targetType == 1 ? arr[0] : arr1[0]
                          color: arr[0]
                        },
                        {
                          offset: 1,
                          // color: obj.targetType == 1 ? arr[1] : arr1[1]
                          color: arr[1]
                        }
                      ]
                    }
                  }
                },
                {
                  // name: "自动化测点",
                  type: "line",
                  data: obj.person,
                  smooth: true,
                  symbol: "circle",
                  symbolSize: 4,
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            // color: obj.targetType == 1 ? arr[0] : arr1[0]
                            color: arr1[0]
                          },

                          {
                            offset: 1,
                            // color: obj.targetType == 1 ? arr[1] : arr1[1]
                            color: arr1[1]
                          }
                        ],
                        false
                      )
                    }
                  },
                  lineStyle: {
                    type: "solid",
                    width: 1.5,
                    color: {
                      colorStops: [
                        {
                          offset: 0,
                          // color: obj.targetType == 1 ? arr[0] : arr1[0]
                          color: arr1[0]
                        },
                        {
                          offset: 1,
                          // color: obj.targetType == 1 ? arr[1] : arr1[1]
                          color: arr1[1]
                        }
                      ]
                    }
                  }
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          scatter: function (id, xData, data1, data2) {
            this.chart = echarts.init(document.getElementById(id));
            const optionData = {
              color: ["#DCFFA4", "#95FEF3"],
              tooltip: {
                trigger: "axis"
              },
              grid: {
                top: "35",
                left: "8%",
                right: "4%",
                bottom: "0%",
                containLabel: true
              },
              title: {
                // show: titleShow, // 是否显示title
                show: !data1.length && !data2.length,

                text: "暂无数据",
                top: "40",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 16,
                  fontWeight: 200
                }
              },
              xAxis: {
                type: "time",
                // type: "category",
                // data: xData,
                min: Math.min(...xData),
                max: Math.max(...xData),
                splitNumber: xData.length,
                splitLine: {
                  show: false
                },
                axisLine: {
                  lineStyle: {
                    color: "#1c2b51",
                    type: "dashed"
                  }
                },
                axisLabel: {
                  color: "#8A94A6",
                  // interval: Math.ceil(time.length / 6),

                  fontSize: 14,
                  formatter: function (value) {
                    console.log("value", value);
                    // return value.split(" ").join("\n");

                    return formateDate(value);
                  }
                }
              },
              yAxis: {
                name: "%",
                nameGap: 20,
                nameTextStyle: {
                  color: "#58637f",
                  fontSize: 14
                },
                axisLabel: {
                  //  y轴左侧的文字
                  color: "#58637f",
                  fontSize: 14
                },
                axisLine: {
                  //   y轴的颜色
                  show: false,
                  lineStyle: {
                    color: "#9D9D9D"
                  }
                },
                splitLine: {
                  lineStyle: {
                    color: "#1c2b51",
                    type: "dashed",
                    show: false
                  }
                },
                axisTick: {
                  show: false
                }
              },
              series: [
                {
                  data: data1,
                  type: "scatter",
                  symbolSize: 8,

                  datasetIndex: 1
                },
                {
                  data: data2,
                  type: "scatter",
                  symbolSize: 8,

                  datasetIndex: 1
                }
              ]
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          distribution: function (id, data) {
            this.chart = echarts.init(document.getElementById(id));
            console.log("差率分布", data);
            let arr = ["自动化测点", "人工测点"],
              color = ["#93FBD2", "#F9FF8B"],
              colorL = ["#CBAC40", "#F4D04C", "#C4983F", "#E2DD71", "#E6A051", "#E7BB6A"],
              colorY = ["#98F9A4", "#72D6F3", "#42C5C1", "#47ACA2", "#5FD0CA", "#65F0F3", "#94FCF2", "#00C3A4", "#088773", "#3DDDC4", "#1FAEBA", "#3889B9"];

            let xdata = [],
              y = 0,
              l = 0;

            data.forEach((item, index) => {
              if (y == colorY.length - 1) {
                y = 0;
              }
              if (l == colorL.length - 1) {
                l = 0;
              }
              let reg = {
                // name: item.targetType == 1 ? arr[0] : arr[1],
                itemStyle: {
                  color: item.targetType == 1 ? color[0] : color[1]
                },
                children: []
              };

              if (item.list && item.list.length) {
                item.list.forEach((itm) => {
                  let child = {
                    name: itm.profile,
                    value: parseFloat(itm.profileRate),
                    itemStyle: {
                      color: item.targetType == 1 ? colorY[y] : colorL[l]
                    }
                  };
                  reg.children.push(child);
                  if (item.targetType == 1) {
                    y++;
                  } else {
                    l++;
                  }
                });
              }

              xdata.push(reg);
            });

            console.log("过滤后数据", xdata);

            const optionData = {
              title: {
                // show: titleShow, // 是否显示title
                show: !xdata.length,

                text: "暂无数据",
                top: "40",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 12,
                  fontWeight: 200
                }
              },
              series: {
                type: "sunburst",
                data: xdata,
                radius: [0, "95%"],
                sort: undefined,
                emphasis: {
                  focus: "ancestor"
                },
                levels: [
                  {},
                  {
                    r0: "25%",
                    r: "45%",
                    itemStyle: {
                      borderWidth: 0
                    },
                    label: {
                      rotate: "tangential"
                    }
                  },
                  {
                    r0: "50%",
                    r: "100%",

                    label: {
                      // position: "outside",
                      align: "left",
                      color: "#ffffff",
                      fontSize: "12px"
                    },
                    itemStyle: {
                      borderWidth: 0
                    }
                  }
                  // {
                  //   r0: "70%",
                  //   r: "72%",
                  //   label: {
                  //     position: "outside",
                  //     padding: 3,
                  //     silent: false
                  //   },
                  //   itemStyle: {
                  //     borderWidth: 0
                  //   }
                  // }
                ]
              }
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          },
          // 状况评估
          weightDistribution: function (id, data) {
            this.chart = echarts.init(document.getElementById(id));
            console.log("差率分布", data);
            // let arr = ["自动化测点", "人工测点"],
            //   color = ["#93FBD2", "#F9FF8B"],
            //   colorL = ["#CBAC40", "#F4D04C", "#C4983F", "#E2DD71", "#E6A051", "#E7BB6A"],
            //   colorY = ["#98F9A4", "#72D6F3", "#42C5C1", "#47ACA2", "#5FD0CA", "#65F0F3", "#94FCF2", "#00C3A4", "#088773", "#3DDDC4", "#1FAEBA", "#3889B9"];
            //     7        2        1
            let colort = ["#FEF093", "#93FBD2", "#3889B9"];
            let color12 = ["#E4F884"];
            let color22 = ["#6DEFBB", "#85FBCC"];
            let color32 = ["#59B3E8"];
            let color13 = ["#F3E36C", "#CBAC40", "#F4D04C", "#C4983F", "#E2DD71", "#E6A051", "#E7BB6A"];
            let color23 = ["#6DEFBB", "#A5FDB0"];
            let color33 = ["#72D6F3"];

            let xdata = [],
              y = 0,
              l = 0;

            data.forEach((item, index) => {
              // if (y == colorY.length - 1) {
              //   y = 0;
              // }
              // if (l == colorL.length - 1) {
              //   l = 0;
              // }
              let reg = {
                // name: item.targetType == 1 ? arr[0] : arr[1],
                name: item.name,
                itemStyle: {
                  // color: item.targetType == 1 ? color[0] : color[1]
                  color: ""
                  // value: parseFloat(item.factor) * 100
                },
                children: []
              };
              let flag = 0;
              if (item.children && item.children.length) {
                item.children.forEach((itm, idx) => {
                  let child = {
                    name: itm.name,
                    // value: parseFloat(itm.profileRate),
                    // value: parseFloat(itm.factor) * 100,
                    itemStyle: {
                      // color: item.targetType == 1 ? colorY[y] : colorL[l]
                      color: ""
                    },
                    children: []
                  };
                  if (idx == 0 && itm.children && item.children.length == 1 && itm.children.length == 1) {
                    reg.itemStyle.color = colort[2];
                    child.itemStyle.color = color32[0];
                    flag = 1;
                  }
                  if (idx == 0 && itm.children && item.children.length == 2 && itm.children.length == 1) {
                    reg.itemStyle.color = colort[1];
                    flag = 2;
                  }
                  if (flag == 2) {
                    child.itemStyle.color = color22[idx];
                  }

                  if (idx == 0 && itm.children && itm.children.length > 2) {
                    reg.itemStyle.color = colort[0];
                    child.itemStyle.color = color12[0];
                    flag = 3;
                  }
                  let arr = [];
                  if (itm.children && itm.children.length) {
                    itm.children.forEach((sitm, ix) => {
                      if (ix == 0 && flag == 1) {
                        arr = JSON.parse(JSON.stringify(color33));
                      } else if (ix == 0 && flag == 2) {
                        arr = JSON.parse(JSON.stringify(color23));
                      } else if (ix == 0 && flag == 3) {
                        arr = JSON.parse(JSON.stringify(color13));
                      }

                      let schild = {
                        name: sitm.name,
                        // value: parseFloat(sitm.factor) * 100,
                        value: parseFloat(sitm.factor) * parseFloat(itm.factor) * parseFloat(item.factor),
                        itemStyle: {
                          color: arr[ix]
                        }
                      };

                      child.children.push(schild);
                    });
                  }
                  reg.children.push(child);
                  // if (item.targetType == 1) {
                  //   y++;
                  // } else {
                  //   l++;
                  // }
                });
              }

              xdata.push(reg);
            });

            console.log("过滤后数据", xdata);

            const optionData = {
              title: {
                // show: titleShow, // 是否显示title
                show: !xdata.length,

                text: "暂无数据",
                top: "40",
                left: "center",
                // top: 'center',
                textStyle: {
                  color: "white",
                  fontSize: 12,
                  fontWeight: 200
                }
              },
              series: {
                type: "sunburst",
                data: xdata,
                radius: [0, "95%"],
                sort: undefined,
                emphasis: {
                  // focus: "ancestor"
                },
                levels: [
                  {},
                  {
                    r0: "25%",
                    r: "45%",
                    itemStyle: {
                      borderWidth: 1
                    },
                    label: {
                      rotate: "tangential",
                      color: "#24304F",
                      fontSize: "12px"
                    }
                  },
                  {
                    r0: "45%",
                    r: "70%",

                    label: {
                      // position: "outside",
                      // align: "left",
                      color: "#24304F",
                      fontSize: "12px",

                      rotate: "tangential"
                    },
                    itemStyle: {
                      borderWidth: 1
                    }
                  },
                  {
                    r0: "70%",
                    r: "74%",
                    label: {
                      position: "outside",
                      color: "#ffffff",
                      fontSize: "12px",
                      padding: 3,
                      silent: false
                    },
                    itemStyle: {
                      borderWidth: 1
                    }
                  }
                ]
              }
            };

            this.chart.setOption(optionData);
            window.addEventListener("resize", () => {
              this.chart.resize();
            });
          }
        };
      }
    }
  });
};

export default {
  install
};
