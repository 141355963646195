/**
 * 各种画echarts图表的方法都封装在这里
 */

// import echarts from 'echarts'
import * as echarts from "echarts";
// import tuli01 from '../assets/img/tuli01.png';
// import tuli02 from '../assets/img/tuli02.png';

const dataZoom =  [
    {
        show: true,
        realtime: true,
        start: 0,
        end: 100,
        textStyle: {
            color: '#58637f' //滚动条两边字体样式
        },
        fillerColor: "rgba(39, 114, 240, 0.2)", //选中范围的填充颜色
        handleColor: "#194fdb", //滑块两端的线
        borderColor: "rgba(255,255,255,0.00)" //边框
    },
    // {
    //     type: "inside",
    //     realtime: true,
    //     start: 0,
    //     end: 10
    // }
]

let areaStyle1 = {
    normal: {
        color: new echarts.graphic.LinearGradient(
        0,
        0,
        0,
        1,
        [
            {
                offset: 0,
                color: 'rgba(39,114,240,0.50)'
            },
            {
                offset: 0.8,
                color: 'rgba(39,114,240,0.00)'
            },
            {
                offset: 1,
                color: 'rgba(39,114,240,0.00)'
            }
        ],
        false
        )
    }
}
let areaStyle2 = {
    normal: {
        color: new echarts.graphic.LinearGradient(
        0,
        0,
        0,
        1,
        [
            {
                offset: 0,
                color: 'rgba(247,181,0,0.50)'
            },
            {
                offset: 0.8,
                color: 'rgba(247,181,0,0.00)'
            },
            {
                offset: 1,
                color: 'rgba(247,181,0,0.00)'
            }
        ],
        false
        )
    }
}




const install = function(Vue) {
    Object.defineProperties(Vue.prototype, {
        $chart2: {
            get() {
                return {
                    //  动静态  d, xData, yData, name, lineColor,  max, min
                    line1: function(id, time, data, name, lineColor, max, min) {
                        this.chart = echarts.init(document.getElementById(id));
                        // this.chart.clear();
                        // if(areaType==1){
                        //     var areaStyle = areaStyle1
                        // } else if(areaType==2){    
                        //     var areaStyle = areaStyle2
                        // }else{
                        //     var areaStyle = 1
                        // }
                        if(time.length == 0){
                            var titleShow = true
                        }else{
                            var titleShow = false
                        }

                        const optionData = {
                            dataZoom: dataZoom,
                              grid: {
                                top: "20%",
                                left: "4%",
                                right: "4%",
                                bottom: "20%",
                                containLabel: true
                              },
                              title: {
                                show: titleShow, // 是否显示title
                                text: "暂无数据",
                                top: "40",
                                left: "center",
                                // top: 'center',
                                textStyle: {
                                    color: "white",
                                    fontSize: 16,
                                    fontWeight: 200
                                }
                            },
                              tooltip: {
                                trigger: "axis"
                              },
                              xAxis: {
                                type: "category",
                                axisLabel: {
                                  color: "#cdd0d8",
                                  interval: Math.ceil(time.length / 6),
                                  fontSize: 12,
                                //   rotate: 4,
                                  showMinLabel: true,
		                              showMaxLabel: true,
                                //   showMinLabel: false,
                                  formatter:function(value){
                                    return value.split(" ").join("\n");
                                 }
                                },
                                axisLine: {
                                  lineStyle: {
                                    color: "#1c2b51",
                                    // type: "dashed"
                                  }
                                },
                                axisTick: {
                                  show: false
                                },
                                data: time
                              },
                              yAxis: [
                                {
                                  type: "value",
                                  name: name,
                                  nameLocation: 'middle',
                                  nameGap: 80,
                                  nameTextStyle: {
                                    color: "#cdd0d8",
                                    fontSize: 14
                                  },
                                  axisLabel: {    //  y轴左侧的文字
                                    color: "#cdd0d8",
                                    fontSize: 14
                                  },
                                  axisLine: {    //   y轴的颜色
                                    show: false,
                                    lineStyle: {
                                      color: "#9D9D9D"
                                    }
                                  },
                                  splitLine: {
                                    lineStyle: {
                                      color: "#1c2b51",
                                      type: "dashed"
                                    }
                                  },
                                  axisTick: {
                                    show: false
                                  }
                                },
                                // {
                                //   type: "value",
                                //   axisLine: {
                                //     lineStyle: {
                                //       color: "#9D9D9D"
                                //     }
                                //   },
                                //   axisTick: {
                                //     show: false
                                //   }
                                // }
                              ],
                              series: [
                                {
                                  name: "",
                                  data: data,
                                  type: "line",
                                  symbol: "none",
                                  areaStyle: {
                                    normal: {
                                      color: new echarts.graphic.LinearGradient(
                                      0,
                                      0,
                                      0,
                                      1,
                                      [
                                          {
                                              offset: 0,
                                              color: lineColor
                                          },
                                          {
                                              offset: 0.7,
                                              color: 'rgba(255,255,255,0.00)'
                                          },
                                          {
                                              offset: 1,
                                              color: 'rgba(255,255,255,0.00)'
                                          }
                                      ],
                                      false
                                      )
                                  }
                                  },
                                  itemStyle: {
                                    color: "#6DB5C7"
                                  },
                                  lineStyle: {
                                    color: lineColor,
                                    width: 2
                                  }
                                }
                              ]
                        };

                        if(max){
                          optionData.yAxis[0].max = max
                          optionData.yAxis[0].min = min
                        }

                        this.chart.setOption(optionData);
                        window.addEventListener("resize", () => {
                            this.chart.resize();
                        });
                    },
                    // 频谱
                    line2: function(id, time, data, name, lineColor,areaType, xname, name1, max, min) {
                      this.chart = echarts.init(document.getElementById(id));
                      // this.chart.clear();
                      if(areaType==1){
                          var areaStyle = areaStyle1
                      } else if(areaType==2){    
                          var areaStyle = areaStyle2
                      }else{
                          var areaStyle = 1
                      }
                      if(time.length == 0){
                          var titleShow = true
                      }else{
                          var titleShow = false
                      }

                      const optionData = {
                          dataZoom: dataZoom,
                            grid: {
                              top: "20%",
                              left: "8%",
                              right: "4%",
                              bottom: "20%",
                              containLabel: true
                            },
                            title: {
                              show: titleShow, // 是否显示title
                              text: "暂无数据",
                              top: "40",
                              left: "center",
                              // top: 'center',
                              textStyle: {
                                  color: "white",
                                  fontSize: 16,
                                  fontWeight: 200
                              }
                          },
                            tooltip: {
                              trigger: "axis"
                            },
                            xAxis: {
                              name: xname,
                              nameTextStyle: { // x轴name的样式调整
                                color: '#58637f', 
                              },
                              nameRotate: 270,
                              type: "category",
                              axisLabel: {
                                color: "#cdd0d8",
                                interval: Math.ceil(time.length / 6),
                                fontSize: 12,
                              //   rotate: 4,
                                showMinLabel: true,
                                showMaxLabel: true,
                              //   showMinLabel: false,
                                formatter:function(value){
                                  return value.split(" ").join("\n");
                               }
                              },
                              axisLine: {
                                lineStyle: {
                                  color: "#1c2b51",
                                  // type: "dashed"
                                }
                              },
                              axisTick: {
                                show: false
                              },
                              data: time
                            },
                            yAxis: [
                              {
                                type: "value",
                                name: name,
                                nameTextStyle: {
                                  color: "#58637f",
                                  fontSize: 14
                                },
                                axisLabel: {    //  y轴左侧的文字
                                  color: "#58637f",
                                  fontSize: 14
                                },
                                axisLine: {    //   y轴的颜色
                                  show: false,
                                  lineStyle: {
                                    color: "#9D9D9D"
                                  }
                                },
                                splitLine: {
                                  lineStyle: {
                                    color: "#1c2b51",
                                    type: "dashed"
                                  }
                                },
                                axisTick: {
                                  show: false
                                }
                              },
                              // {
                              //   type: "value",
                              //   axisLine: {
                              //     lineStyle: {
                              //       color: "#9D9D9D"
                              //     }
                              //   },
                              //   axisTick: {
                              //     show: false
                              //   }
                              // }
                            ],
                            series: [
                              {
                                name: name1,
                                data: data,
                                type: "line",
                                symbol: "none",
                                areaStyle: areaStyle,
                                itemStyle: {
                                  color: "#6DB5C7"
                                },
                                lineStyle: {
                                  color: lineColor,
                                  width: 2
                                }
                              }
                            ]
                      };

                      if(max){
                        optionData.yAxis[0].max = max
                        optionData.yAxis[0].min = min
                      }

                      this.chart.setOption(optionData);
                      window.addEventListener("resize", () => {
                          this.chart.resize();
                      });
                  },
                };
            }
        }
    });
};

export default {
    install
};